
import { createSlice, configureStore } from '@reduxjs/toolkit';

//MOVEMENTS (doesn't work yet)
const initialState = {
    path: [],

};

const pathSlice = createSlice({
    name: 'path',
    initialState: initialState,
    reducers: {
        setFinalPath(state, action) {
            state.path = action.payload
        },

    },
});
export const {setFinalPath} = pathSlice.actions;
export default pathSlice.reducer;
