/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the adminRoutes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the adminRoutes and delete the adminRoutes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing adminRoutes in the adminRoutes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other adminRoutes
  inside (nested adminRoutes), you need to pass the nested adminRoutes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Partner from "../layouts/partner";
import NewClient from "../layouts/newClient";
import Client from "../layouts/client";
import Afftarget from "../layouts/afftarget";
import Support from "../layouts/support";
import Feedback from "../layouts/feedback";
import CreatePost from "../layouts/createPost";
import Posts from "../layouts/post";
import Payments from "../layouts/payments";
import BuyedPost from "../layouts/buyedPost";

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "AffTarget",
    key: "afftarget",
    icon: <Icon fontSize="small">my_location</Icon>,
    route: "afftarget",
    component: <Afftarget />,
  },
  {
    type: "collapse",
    name: "Client",
    key: "client",
    icon: <Icon fontSize="small">person</Icon>,
    route: "client",
    component: <Client />,
  },
  {
    type: "collapse",
    name: "Feedbacks",
    key: "feedbacks",
    icon: <Icon fontSize="small">feedback</Icon>,
    route: "feedbacks",
    component: <Feedback />,
  },

  {
    type: "collapse",
    name: "NewClient",
    key: "newclient",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "newclient",
    component: <NewClient />,
  },
  {
    type: "collapse",
    name: "Partner",
    key: "partner",
    icon: <Icon fontSize="small">diversity_3</Icon>,
    route: "partner",
    component: <Partner />,
  },
  {
    type: "collapse",
    name: "Create Post",
    key: "post",
    icon: <Icon fontSize="small">note_add</Icon>,
    route: "post",
    component: <CreatePost />,
  },
  {
    type: "collapse",
    name: "Posts",
    key: "posts",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "posts",
    component: <Posts />,
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="small">currency_bitcoin</Icon>,
    route: "payments",
    component: <Payments />,
  },
  {
    type: "collapse",
    name: "buyed",
    key: "buyed",
    icon: <Icon fontSize="small">payments</Icon>,
    route: "buyed",
    component: <BuyedPost />,
  },
  {
    type: "collapse",
    name: "Chat",
    key: "chat",
    icon: <Icon fontSize="small">support_agent</Icon>,
    route: "chat",
    component: <Support />,
  },

];

export default adminRoutes;
