import React, { useEffect, useState } from "react";
import { Treebeard } from "react-treebeard";
import decorators from "react-treebeard/lib/components/decorators";
import defaultTheme from "react-treebeard/lib/themes/default";
import MDTypography from "../../components/MDTypography";
import style from "./TreeStyle";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import {FormControlLabel, Popover, Radio} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {setCart} from "../../Redux/slices/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import MDButton from "../../components/MDButton";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "../../components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDInput from "../../components/MDInput";
import {useNavigate} from "react-router-dom";
import {errorToast, infoToast} from "../../utils/toastify";
import {setFinalPath} from "../../Redux/slices/pathSlice";
import {useGetBalanceQuery} from "../../Redux/slices/userApiSlice";
import {ProgressBar} from "react-loader-spinner";


function parseBoolean(value) {
    if (value === "true" || value === "1") {
        return true;
    } else if (value === "false" || value === "0") {
        return false;
    }
}
function getBaseName(filename) {
    // Получаем имя файла без расширения
    let baseName = filename.substring(0, filename.lastIndexOf('.'));

    // Проверяем длину имени файла
    if (baseName.length > 26) {
        // Обрезаем имя файла до 23 символов и добавляем 3 точки
        baseName = baseName.substring(0, 24) + "...";
    }

    return baseName;
}

function getFileExtension(filename) {
    const index = filename.lastIndexOf('.');
    return index !== -1 ? filename.substring(index + 1) : '';
}
const CustomHeader = ({ node, style, funcu }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const user = useSelector(state => state.auth.userInfo.user)

    const {data: balance} = useGetBalanceQuery(user ? user.username: '')

    const handleChange = (event, checked) => {
        if (balance.data.balance < 200){
            errorToast("You should add balance to add files to cart on 0,005BTC")
        } else {
            if (!isAllFilesChecked(node, cart)){
                // Добавить все файлы в папку в корзину
                addAllFilesInFolder(node);
            } else {
                // Удалить все файлы в папке из корзины
                removeAllFilesInFolder(node);
            }
            setChecked(!checked);
        }
    };

    const addAllFilesInFolder = (folderNode) => {
        const filesToAdd = [];
        const traverseFolder = (node) => {
            if (node.children) {
                node.children.forEach(child => {
                    traverseFolder(child);
                });
            } else {
                // Добавить файл в корзину
                filesToAdd.push(node);
            }
        };

        traverseFolder(folderNode);
        let price = 0
        filesToAdd.forEach(item => {
            price += item.price
        })
        dispatch(setCart({totalPrice: cart.totalPrice + price, files: [...cart.files, ...filesToAdd]}));


    };
    const removeAllFilesInFolder = (folderNode) => {
        const filesToRemove = [];
        const traverseFolder = (node) => {
            if (node.children) {
                node.children.forEach(child => {
                    traverseFolder(child);
                });
            } else {
                // Добавить файл в массив для удаления
                filesToRemove.push(node.path);
            }
        };

        traverseFolder(folderNode);

        dispatch(
            setCart({
                totalPrice: cart.totalPrice - filesToRemove.reduce(
                    (acc, filePath) => {
                        const file = cart.files.find(file => file.path === filePath);
                        return acc + (file && file.price ? file.price : 0); // Проверка на наличие цены
                    },
                    0
                ),
                files: cart.files.filter(file => !filesToRemove.includes(file.path))
            })
        );
    };

    const buyNow = () => {
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node]}))
        navigate('/client/cart')

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onNode = (node) => {
        funcu(node)
    }
    // const isAllFilesChecked = (node, cart) => {
    //     // Рекурсивная функция для проверки всех файлов в папке
    //
    //     if (node.children) {
    //         return node.children.every(child => {
    //             return cart.files.some(item => item.path === child.path) ||
    //                 isAllFilesChecked(child, cart);
    //         });
    //     } else {
    //         return cart.files.some(item => item.path === node.path && node.path && item.path);
    //     }
    // };
    const isAllFilesChecked = (node, cart) => {
        // Рекурсивная функция для проверки всех файлов в папке

        // Проверка на пустоту папки
        if (!node.children || node.children.length === 0) {
            return false; // Возвращаем false, если папка пуста
        } else {
            return node.children.every(child => {
                return cart.files.some(item => item.path === child.path) ||
                    isAllFilesChecked(child, cart);
            });
        }
    };

    const isEmpty = (node) => {
        // Рекурсивная функция для проверки всех файлов в папке

        // Проверка на пустоту папки
        return true
    };
    const newStr =  node.name.replace(/\s/g, '');

    return (
        isEmpty(node) ? (
            <div style={style.base}>
                <div style={{ ...style.title, display: "flex", color: 'black' }}>
                    <Grid container>
                        <Grid item xs={10}>
                            {node.type === 'file' ? (
                                <>
                                    <Grid container style={{width: '110%'}}>
                                        {/*<Grid item xs={1}>*/}
                                        {/*    <FormControlLabel control={<Checkbox style={{top: -5}}  value={checked} onChange={handleChange} />}/>*/}

                                        {/*        </Grid>*/}
                                        {/*<Grid item xs={1}>*/}
                                        {/*    <img style={{width: 25}} src={`${process.env.REACT_APP_BASE_URL}${localStorage.getItem('image')}`}/>*/}
                                        {/*</Grid>*/}
                                        <Grid item xs={3} >
                                            <Tooltip title={node.name}>
                                                <p>
                                                    {getBaseName(node.name)}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={'Extension of file'}>
                                                <p>
                                                    .{getFileExtension(node.name)}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title={'Date of last change'}>
                                                <p>
                                                    <Icon>access_time</Icon> {node.date}

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title={'Weight of file'}>
                                                <p>
                                                    <Icon>cloud_download</Icon> {node.weight} KB

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={'Price'}>
                                                <p>
                                                    <Icon>sell</Icon> {node.price}$

                                                </p>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title={"Buy now"}>
                                                <MDButton onClick={buyNow} variant={'contained'} color={'info'}>
                                                    <Icon>flash_on</Icon>
                                                </MDButton>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={1}>

                                            <Tooltip title={'Add to cart'} onClick={handleChange}>
                                                <MDButton variant={'contained'} color={cart.files.some(item => item.path === node.path) ? "error" : "success"}>
                                                    <Icon>{cart.files.some(item => item.path === node.path) ? "remove_shopping_cart" : "add_shopping_cart"}</Icon>
                                                </MDButton>
                                            </Tooltip>
                                        </Grid>

                                    </Grid>


                                </>
                            ) : <>
                                <>
                                    <Grid container>
                                        <Grid item xs={1} onClick={funcu}>
                                            <Icon>folder</Icon>
                                        </Grid>
                                        <Divider orientation={'vertical'} flexItem/>
                                        <Grid item xs={10} style={{marginTop: 10}} onClick={funcu}>
                                            {node.name}
                                        </Grid>
                                        {node.children && (
                                            <Tooltip title="Select all files in folder">
                                                <Checkbox
                                                    style={{position: 'absolute', left: '30%', top: '-10%'}}
                                                    checked={isAllFilesChecked(node, cart)}
                                                    onClick={handleChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </>
                            </> }
                        </Grid>

                    </Grid>
                    <Divider/>
                </div>
            </div>
        ) : null
    );
};

// Кастомный контейнер для отображения узлов
const CustomContainer = ({ style, decorators, terminal, onClick, node, toggle }) => {
    const [checked, setChecked] = React.useState(true);
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked){
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node.path]}))
        } else {
            const finalFiles = cart.files.filter(item => item.path !== node.path);
            dispatch(setCart({totalPrice: cart.totalPrice - node.price, files: finalFiles}))
        }

    };
    const isEmpty = (node) => {
        // Рекурсивная функция для проверки всех файлов в папке

        // Проверка на пустоту папки
        return true
    };
    return (
        isEmpty(node) ? (

            <div  style={style.container[0]}>
                <decorators.Header node={node} funcu={onClick} style={style.header} />
                <span >

            </span>
                <Divider/>
            </div>
        ) : null
    );
};

// Оборачиваем кастомные декораторы в функциональный компонент
const CustomDecorators = {
    Header: CustomHeader,
    Container: CustomContainer,
};

// Основной компонент
const TreeView = ({ dataa, post, rows, onSet }) => {
    const path = useSelector(state => state.path.path)
    const dispatch = useDispatch()
    const [sort, setSort] = useState("name");
    const isBlocked = localStorage.getItem('isBlocked')


    const handleChange = (event, newValue) => {
        setSort(newValue);
    };
    const [loading, setLoading] = useState(false)

    const [currentPath, setCurrentPath] = useState([]); // Хранит текущее имя узла
    const [allPaths, setAllPaths] = useState([])
    const [treeData, setTreeData] = useState(dataa.data); // Изначальные данные дерева
    const [treeData2, setTreeData2] = useState(dataa.data); // Изначальные данные дерева

    const user = useSelector(state => state.auth.userInfo.user)

    const {data: balance} = useGetBalanceQuery(user ? user.username: '')
    const [fullData, setFullData] = useState(dataa.data); // Хранит полные данные дерева для возврата
    useEffect(() => {

            const sortedData = sortFiles([...dataa.data], sort, false); // Клонируем и сортируем данные
            setTreeData(sortedData); // Обновляем состояние с отсортированными данными
            setTreeData2(sortedData)
        setLoading(false)

        // setFullData(dataa.data)
    }, [dataa])
    function getPaths(node, path = []) {
        // Добавляем текущий узел в путь
        const currentPath = [...path, node.name];

        // Если у узла есть дочерние элементы, рекурсивно продолжаем
        if (node.children && node.children.length > 0) {
            let paths = [];
            for (const child of node.children) {
                paths = paths.concat(getPaths(child, currentPath));
            }
            return paths;
        } else {
            // Если дочерних элементов нет, возвращаем текущий путь
            return [currentPath];
        }
    }

    const recursiveSort = (nodes) => {
        return nodes.map(node => {
            if (node.children) {
                // Рекурсивно сортируем дочерние узлы
                const sortedChildren = recursiveSort(node.children);
                return { ...node, children: sortedChildren };
            }
            return node;
        }).sort((a, b) => a.name.localeCompare(b.name)); // Сортируем по имени
    };

    const sortByCurrentPath = () => {
        const lastNodeName = path[path.length - 1];
        const parentNode = fullData.children.find(node => node.name === lastNodeName);

        if (parentNode && parentNode.children) {
            const sortedChildren = recursiveSort(parentNode.children);
            setTreeData(sortedChildren);
        }
    };


    // Обработчик для переключения состояния узлов
    function findPathToFolder(data, targetName) {
        // Проходим по всем элементам массива
        for (const node of data) {
            // Добавляем текущее имя узла к пути
            const currentPath = [...path, node.name];

            // Проверяем, совпадает ли текущее имя узла с искомым
            if (node.name === targetName && (node.type === 'folder' || node.type === 'computer' || !node.type)) {
                // Проверяем, что текущий путь соответствует заданному path
                if (currentPath.length > path.length && currentPath.slice(0, path.length).join('') === path.join('')) {
                    return currentPath; // Возвращаем путь, если нашли
                }
            }

            // Если у узла есть дочерние элементы, продолжаем поиск, только если текущий путь соответствует заданному path
            if (node.children && node.children.length > 0 && currentPath.slice(0, path.length).join('') === path.join('')) {
                const result = findPathToFolder(node.children, targetName, currentPath);
                if (result.length !== 0) {
                    return result; // Возвращаем найденный путь сразу, как только нашли
                }
            }
        }
        return []; // Возвращаем пустой массив, если ничего не нашли
    }







// Применение функции
//     const targetName = 'Grandchild 1';
//     const pathToTarget = findPathToFolder(treeData, targetName);
//     console.log(pathToTarget);

    const  handleToggle = (node) => {

        if (node.children && node.children.length){

                if (node.children && node.children.length > 0) {
                    if (path.length >= 3 && balance.data.balance < 200){
                        errorToast("You should add balance to see more on 0,005BTC")
                    } else{
                        onSet(1)
                        setLoading(true)
                        const targetName = node.name;

                        const pathToTarget = findPathToFolder(fullData, targetName); // Ищем путь с учетом уровня
                        dispatch(setFinalPath(pathToTarget))

                    }
                }

        }
        if (node.children.length === 0){
            errorToast("There are no files in this folder")
        }
    };

    // Функция для возврата на уровень вверх
    const goBack = () => {
        onSet(1)
        setLoading(true)
        // Устанавливаем новый путь, убирая последний элемент
        const prePath = path.slice(0, -1); // Пропускаем первый элемент
        dispatch(setFinalPath(prePath))


    };



    const searchNodesInPath = (nodes, currentPath, query) => {
        setLoading(true)
        // Если текущий путь пуст, возвращаем пустой массив
        if (parseBoolean(isBlocked)) {
            errorToast("You should add balance to see more on 0,005BTC")
        } else {

            // Функция для рекурсивного поиска по всем узлам
            const searchNodes = (nodes) => {
                const results = [];
                nodes.forEach(node => {
                    // Проверяем соответствие имени
                    if (node.name.toLowerCase().includes(query.toLowerCase())) {
                        results.push(node);
                    }

                    // Рекурсивно ищем в дочерних узлах
                    if (node.children) {
                        results.push(...searchNodes(node.children));
                    }
                });
                return results;
            };

            // Вызываем рекурсивную функцию для поиска во всех узлах
            const foundNodes = searchNodes(nodes);
            setLoading(false)
            return foundNodes;
        }
    };





    // Функция для перехода на родительский узел по клику
    const handleParentClick = (name) => {
        const newPath = [...currentPath.slice(0, currentPath.length - 1), name]; // Обновляем путь
        const parentNode = fullData.children.find(child => child.name === name); // Находим узел родителя

        if (parentNode && parentNode.children) {
            setTreeData(parentNode.children); // Устанавливаем дочерние узлы для отображения
            setTreeData2(parentNode.children); // Устанавливаем дочерние узлы для отображения


        }
        dispatch(setFinalPath(newPath))
        setCurrentPath(newPath); // Обновляем текущее состояние пути
    };
    const handleSearch = (event) => {
        const query = event.target.value.trim();

        if (query) {
            // Поиск только по дочерним элементам в пределах текущего пути

            const results = searchNodesInPath(fullData, path, query);
            const limitedResults = results.slice(0, rows); // Ограничение результата 5 элементами
            setTreeData(limitedResults.length > 0 ? limitedResults : []); // Устанавливаем найденные результаты или пустой массив
            setTreeData2(limitedResults.length > 0 ? limitedResults : []); // Устанавливаем найденные результаты или пустой массив
        } else {
            // Если строка пустая, необходимо вернуть дочерние узлы текущего узла в currentPath
            setTreeData(dataa.data); // Устанавливаем найденные результаты или пустой массив
            setTreeData2(dataa.data);
        }
    };



    const [sortKey, setSortKey] = useState('name');

    const sortFiles = (files, sortKey, isAscending) => {
        const sortedFiles = [];
        const folders = [];
        const nonFolders = [];

        // Разделяем файлы на папки и не-папки
        files.forEach(file => {
            if (file.type === 'folder') {
                folders.push(file);
            } else {
                nonFolders.push(file);
            }
        });

        // Сортируем папки
        folders.sort((a, b) => {
            let comparison = 0;
            switch (sortKey) {
                case 'price':
                    comparison = parseFloat(a.price) - parseFloat(b.price);
                    break;
                case 'weight':
                    comparison =  parseFloat(a.weight) -parseFloat(b.weight);
                    break;
                case 'date':
                    comparison = new Date(a.date) - new Date(b.date) ;
                    break;
                case 'extension':
                    const aExt = getFileExtension(b.name).toLowerCase();
                    const bExt = getFileExtension(a.name).toLowerCase();
                    comparison = aExt.localeCompare(bExt);
                    break;
                case 'name':
                    comparison = b.name.toLowerCase().localeCompare(a.name.toLowerCase());
                    break;
                case 'reverse_price':
                    comparison = parseFloat(b.price) - parseFloat(a.price);
                    break;
                case 'reverse_weight':
                    comparison =  parseFloat(b.weight) -parseFloat(a.weight);
                    break;
                case 'reverse_date':
                    comparison = new Date(b.date) - new Date(a.date) ;
                    break;
                case 'reverse_extension':
                    const aExt1 = getFileExtension(b.name).toLowerCase();
                    const bExt1 = getFileExtension(a.name).toLowerCase();
                    comparison = bExt1.localeCompare(aExt1);
                    break;
                case 'reverse_name':
                    comparison = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    break;
                default:
                    comparison = 0;
            }

            return isAscending ? comparison : -comparison;
        });

        // Сортируем не-папки (файлы)
        nonFolders.sort((a, b) => {
            let comparison = 0;
            switch (sortKey) {
                case 'price':
                    comparison = parseFloat(a.price) - parseFloat(b.price);
                    break;
                case 'weight':
                    comparison =  parseFloat(a.weight) -parseFloat(b.weight);
                    break;
                case 'date':
                    comparison = new Date(a.date) - new Date(b.date) ;
                    break;
                case 'extension':
                    const aExt = getFileExtension(b.name).toLowerCase();
                    const bExt = getFileExtension(a.name).toLowerCase();
                    comparison = aExt.localeCompare(bExt);
                    break;
                case 'name':
                    comparison = b.name.toLowerCase().localeCompare(a.name.toLowerCase());
                    break;
                case 'reverse_price':
                    comparison = parseFloat(b.price) - parseFloat(a.price);
                    break;
                case 'reverse_weight':
                    comparison =  parseFloat(b.weight) -parseFloat(a.weight);
                    break;
                case 'reverse_date':
                    comparison = new Date(b.date) - new Date(a.date) ;
                    break;
                case 'reverse_extension':
                    const aExt1 = getFileExtension(b.name).toLowerCase();
                    const bExt1 = getFileExtension(a.name).toLowerCase();
                    comparison = bExt1.localeCompare(aExt1);
                    break;
                case 'reverse_name':
                    comparison = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    break;
                default:
                    comparison = 0;
            }

            return isAscending ? comparison : -comparison;
        });

        // Объединяем отсортированные папки и файлы
        return [...folders, ...nonFolders];
    };





    const handleSortChange = (event, newValue) => {
        setSort(newValue);
        const sortedData = sortFiles([...treeData2], newValue, false); // Клонируем и сортируем данные
        setTreeData(sortedData); // Обновляем состояние с отсортированными данными
    };



    return (
        <>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                color={'white'}

            >
                <MDBox bgColor={'white'} color={'black'}
                       borderRadius="lg"
                       p={3}
                >
                    <div className="path-container">
                        {path ? path.join(' > ') : null}
                        {path   && path.length >= 1 && (
                            <MDButton variant={'contained'} style={{marginLeft: 10}} color={'info'} onClick={goBack}><Icon>arrow_back</Icon></MDButton> // Кнопка для возврата на уровень выше
                        )}
                    </div>

                    <MDBox mx={2} mt={3} py={3} px={2}>

                        <Grid container>
                            <Grid item xs={4}>
                                {path.length >= 1  ? <MDInput
                                    type="text"
                                    placeholder="Search..."
                                    onChange={handleSearch}
                                    style={{width: '100%', padding: '10px', borderRadius: '4px'}}
                                /> :    null}
                            </Grid>

                            <Grid item xs={8}>

                                <Tabs value={sort} onChange={handleSortChange} aria-label="basic tabs example">
                                    <Tab label="Sort by Name" value={'name'} />
                                    <Tab label="Sort by Extension" value={'extension'} />
                                    <Tab label="Sort by Price" value={'price'} />

                                    <Tab label="Sort by Weight" value={'weight'}/>
                                    <Tab label="Sort by Date" value={'date'}/>
                                </Tabs>
                                <Tabs value={sort} onChange={handleSortChange} aria-label="basic tabs example">
                                    <Tab label="Reverse Name" value={'reverse_name'} />
                                    <Tab label="Reverse Extension" value={'reverse_extension'} />
                                    <Tab label="Reverse Price" value={'reverse_price'} />

                                    <Tab label="Reverse Weight" value={'reverse_weight'}/>
                                    <Tab label="Reverse Date" value={'reverse_date'}/>
                                </Tabs>
                            </Grid>

                        </Grid>
                    </MDBox>
                </MDBox>
            </MDBox>

            <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
            >
                {loading ?
                    <ProgressBar
                        visible={true}
                        height="130"
                        width="100%"
                        borderColor={'#1A73E8'}
                        color="#4fa94d"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    : <Treebeard
                    style={style}
                    data={treeData} // Для обновления данных дерева
                    decorators={CustomDecorators}
                    onToggle={handleToggle} // Устанавливаем обработчик
                />}
            </MDBox>
        </>
    );
};


export default TreeView;

