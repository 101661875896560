import React, {useState} from 'react';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {
    FormControl,
    FormGroup, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TableCell,
    TableHead,
    TextareaAutosize
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import MDButton from "../../components/MDButton";
import {useCreateTargetMutation} from "../../Redux/slices/targetApiSlice";
import {useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import {DatePicker, DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {useCreatePostMutation, useUploadImagesMutation} from "../../Redux/slices/postApiSlice";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import axios from "axios";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import MDInput from "../../components/MDInput";
import Grid from "@mui/material/Grid";

const CreatePost = () => {
    const [createPost, {isLoading}] = useCreatePostMutation()
    const [uploadImages, {isLoading: loading}] = useUploadImagesMutation()
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [discount, setDiscount] = useState(0)
    const [oneprice, setOneprice] = useState(0)
    const [onediscount, setOnediscount] = useState(0)
    const [link, setLink] = useState('')
    const [zoom, setZoom] = useState('')
    const [site, setSite] = useState('')
    const [sell, setSell] = useState(true)
    const [timer, setTimer] = useState(dayjs(Date.now('yyyy-mm-dd')))
    const [image, setImage] = useState('')
    const [partner, setPartner] = useState(0)
    const [has, setHas] = useState(false)




    const onTitle = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setTitle(e.target.value)
        }
    }
    const onSubtitle = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setSubtitle(e.target.value)
        }
    }
    const onDescription = (data) => {
        let re = /["'<>/+=~`?|№!#$%^&*()]/;
        if(re.test(data)){
            setHas(true)
        } else{
            setDescription(data)
        }
    }
    const onZoom = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setZoom(e.target.value)
        }
    }


    const onSite = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setSite(e.target.value)
        }
    }


    const [brand, setBrand] = useState('')
    const [imagesArr, setImagesArr] = useState([])
    const [uploading, setUploading] = useState(false)
    const [links, setLinks] = useState([])
    const onImages = (elem) => {
        let copy = Object.assign([], imagesArr)
        copy.push(elem)
        setImagesArr(copy)

        console.log(imagesArr)
    }
    const addLink = () => {
        const arr = links
        arr.push(link)
        setLinks(arr)
        setLink('')
    }
    const deleteLink = (item) => {
        const linked = links
        const final = linked.filter((image) => image !== item);
        setLinks(final)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await createPost({title, subtitle, description, images: imagesArr, price, isSell: sell, timer, partnerId: partner, links, oneprice, discount, onediscount})
            infoToast("Success")
        } catch (err) {
            errorToast(err.message)
        }
    }
    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/upload`, formData, config)

            setImage(data)
            setUploading(false)
            onImages(data)
        } catch (error) {
            console.error(error)
            setUploading(false)
        }
    }
    const deleteImage = (imageDelete) => {
        const newImages = imagesArr
        const final = newImages.filter((image) => image !== imageDelete);
        setImagesArr(final)
    }

    return (

        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-10 col-12 m-auto">

                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Create Post
                                    </MDTypography>
                                </MDBox>
                                <div style={{padding: '5%'}} className="card-body">

                                    <form onSubmit={onSubmit}>

                                        <FormGroup>
                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="Title"
                                                value={title}
                                                onChange={onTitle}
                                                fullWidth
                                                required
                                                sx={{mb: 4}}
                                            />
                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="Subtitle"
                                                value={subtitle}
                                                onChange={onSubtitle}
                                                fullWidth
                                                required
                                                sx={{mb: 4}}
                                            />

                                            <MDTypography>Description</MDTypography>
                                            <CKEditor
                                                value={description}
                                                onChange={(e, editor) => {
                                                    const data = editor.getData()
                                                    setDescription(data)
                                                }}
                                                editor={ClassicEditor}>Description</CKEditor>
                                            <FormControl>
                                                <InputLabel
                                                    sx={{marginTop: 5}}

                                                    id="demo-simple-select-label">Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    sx={{width: '50%', marginTop: 5, height: '30px'}}
                                                    label="Age"
                                                    value={sell}
                                                    onChange={e => setSell(e.target.value)}

                                                >
                                                    <MenuItem value={true}>Sell</MenuItem>
                                                    <MenuItem value={false}>Public</MenuItem>

                                                </Select>
                                            </FormControl>
                                            {sell ? (
                                                <FormControl>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                type="number"
                                                                variant='outlined'
                                                                color='secondary'
                                                                label="Price"
                                                                value={price}
                                                                inputProps={{
                                                                    "min": "1"
                                                                }}
                                                                onChange={e => setPrice(e.target.value)}
                                                                required
                                                                fullWidth
                                                                sx={{mb: 4, mt: 5}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                type="number"
                                                                variant='outlined'
                                                                color='secondary'
                                                                label="New Price (for all)"
                                                                value={discount}
                                                                inputProps={{
                                                                    "min": "0"
                                                                }}
                                                                onChange={e => setDiscount(e.target.value)}
                                                                required
                                                                fullWidth
                                                                sx={{mb: 4, mt: 5}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                type="number"
                                                                variant='outlined'
                                                                color='secondary'
                                                                label="Price in one hands"
                                                                value={oneprice}
                                                                inputProps={{
                                                                    "min": "1"
                                                                }}
                                                                onChange={e => setOneprice(e.target.value)}
                                                                required
                                                                fullWidth
                                                                sx={{mb: 4, mt: 5}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                type="number"
                                                                variant='outlined'
                                                                color='secondary'
                                                                label="New Price (in one hands)"
                                                                value={onediscount}
                                                                inputProps={{
                                                                    "min": "0"
                                                                }}
                                                                onChange={e => setOnediscount(e.target.value)}
                                                                required
                                                                fullWidth
                                                                sx={{mb: 4, mt: 5}}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </FormControl>

                                            ) : (
                                                <>
                                                    <FormControl style={{marginTop: '20px', marginBottom: '20px'}}>
                                                        <DatePicker
                                                            label="Timer"
                                                            value={timer}
                                                            onChange={value => setTimer(value)}
                                                        />
                                                    </FormControl>


                                                    <TextField
                                                        type="text"
                                                        variant='outlined'
                                                        color='secondary'
                                                        label="Link"
                                                        value={link}
                                                        onChange={e => setLink(e.target.value)}
                                                        fullWidth
                                                        sx={{mb: 4}}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MDButton onClick={() => addLink(link)} variant={'contained'} color={'info'}><Icon>add_circle</Icon></MDButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />


                                                    <FormControl sx={{margin: '20px'}}>
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                                <TableHead>

                                                                </TableHead>
                                                                <TableBody>
                                                                    {links.map((item) => (
                                                                        <TableRow >

                                                                            <TableCell align="right">{item}</TableCell>

                                                                            <TableCell align="right"><MDButton onClick={() => deleteLink(item)}><Icon>delete</Icon></MDButton></TableCell>

                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                    </FormControl>

                                                </>


                                            )}


                                            <TextField
                                                value={partner}
                                                onChange={e => setPartner(e.target.value)}
                                                type="number"
                                                variant='outlined'
                                                color='secondary'
                                                inputProps={{
                                                    "min": "1"
                                                }}
                                                label="partnerId"
                                                required
                                                fullWidth
                                                sx={{mb: 4}}
                                            />

                                            <MDTypography>Images of Target</MDTypography>
                                            <div style={{marginBottom: 4}}  className="form-control border dropzone dz-clickable"
                                                 id="productImg">
                                                <div className="dz-default dz-message">

                                                    <input className="dz-button"  onChange={uploadFileHandler} placeholder={''} type={'file'}/>.

                                                </div>
                                            </div>
                                            <FormControl sx={{margin: '20px'}}>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>

                                                        </TableHead>
                                                        <TableBody>
                                                            {imagesArr.map((item) => (
                                                                <TableRow >

                                                                    <TableCell align="right">{item}</TableCell>
                                                                    <TableCell align="right"><img style={{width: "15%"}} src={`${process.env.REACT_APP_BASE_URL}${item}`}/></TableCell>
                                                                    <TableCell align="right"><MDButton onClick={() => deleteImage(item)}><Icon>delete</Icon></MDButton></TableCell>

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </FormControl>
                                            <FormControl>
                                                <MDButton style={{marginTop: '5'}} variant="contained" color={'info'} type="submit">{isLoading ? "Loading" : "Publish"}</MDButton>

                                            </FormControl>
                                        </FormGroup>
                                    </form>
                                </div>
                            </Card>
                        </div>
                    </div>

                </div>
                </LocalizationProvider>
            </DashboardLayout>
        </>

    );
};

export default CreatePost;