/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import postTableData from "./data/postTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import React, {useEffect, useState} from "react";
import CreateClient from "../../components/Modals/CreateClient";
import CreatePartner from "../../components/Modals/CreatePartner";
import {useDeleteUserMutation, useGetClientsQuery, useGetPartnersQuery} from "../../Redux/slices/userApiSlice";
import clientTableData from "../client/data/clientTableData";
import {errorToast, infoToast} from "../../utils/toastify";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePassword from "../../components/Modals/UpdatePassword";
import UpdatePasswordPartner from "../../components/Modals/UpdatePasswordPartner";
import {useDeletePostMutation, useGetPostsAdminQuery, useGetPostsQuery} from "../../Redux/slices/postApiSlice";
import {Link, useNavigate} from "react-router-dom";
import {formatter} from "../../utils/fornatter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function Posts() {
    const [open, setOpen] = useState(false)
    const {data: posts} = useGetPostsAdminQuery()
    const [deletePost, {isLoading}] = useDeletePostMutation()
    const { columns } = postTableData();
    const [rows, setRows] = useState([])
    const navigate = useNavigate()
    const [type, setType] = useState("All")
    const handleChange = (event, newValue) => {
        setType(newValue);


    };
    const onDelete = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await deletePost({id}).unwrap()
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }
    const onEdit =  (id) => {
        navigate(`edit/${id}`)
    }


    useEffect(() => {
        let arr = []
        if (posts){
            if (type === "All"){
                arr = posts.data.postsAll.rows.map(item => {
                    return  {
                        id: <MDTypography>{item.id}</MDTypography>,
                        title: (
                            <MDTypography>{item.title}</MDTypography>
                        ),
                        subtitle: (
                            <MDTypography>{item.subtitle}</MDTypography>
                        ),
                        type: (
                            <MDTypography>{item.isSell ? "SELL" : !item.isSell ? "PUBLIC" : "SELL"}</MDTypography>
                        ),

                        date: (
                            <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                        ),
                        action: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                <Link to={`/affadmeowsugarhgvhgvhgv/edit/${item.id}`}>
                                    <Tooltip title={'Edit'}>
                                        <MDButton color={'warning'}  style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={'Delete'}>
                                    <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                                </Tooltip>
                            </MDTypography>
                        ),


                    }
                })

            }
            else if (type === "Public"){
                arr = posts.data.postsPublic.rows.map(item => {
                    return  {
                        id: <MDTypography>{item.id}</MDTypography>,
                        title: (
                            <MDTypography>{item.title}</MDTypography>
                        ),
                        subtitle: (
                            <MDTypography>{item.subtitle}</MDTypography>
                        ),
                        type: (
                            <MDTypography>{item.isSell ? "SELL" : !item.isSell ? "PUBLIC" : "SELL"}</MDTypography>
                        ),

                        date: (
                            <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                        ),
                        action: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                <Link to={`/affadmeowsugarhgvhgvhgv/edit/${item.id}`}>
                                    <Tooltip title={'Edit'}>
                                        <MDButton color={'warning'}  style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={'Delete'}>
                                    <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                                </Tooltip>
                            </MDTypography>
                        ),


                    }
                })

            }
            else if (type === "Sell"){
                arr = posts.data.postsSell.rows.map(item => {
                    return  {
                        id: <MDTypography>{item.id}</MDTypography>,
                        title: (
                            <MDTypography>{item.title}</MDTypography>
                        ),
                        subtitle: (
                            <MDTypography>{item.subtitle}</MDTypography>
                        ),
                        type: (
                            <MDTypography>{item.isSell ? "SELL" : !item.isSell ? "PUBLIC" : "SELL"}</MDTypography>
                        ),

                        date: (
                            <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                        ),
                        action: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                <Link to={`/affadmeowsugarhgvhgvhgv/edit/${item.id}`}>
                                    <Tooltip title={'Edit'}>
                                        <MDButton color={'warning'}  style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={'Delete'}>
                                    <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                                </Tooltip>
                            </MDTypography>
                        ),


                    }
                })

            }
            else if (type === "Discount"){
                arr = posts.data.postsDiscount.rows.map(item => {
                    return  {
                        id: <MDTypography>{item.id}</MDTypography>,
                        title: (
                            <MDTypography>{item.title}</MDTypography>
                        ),
                        subtitle: (
                            <MDTypography>{item.subtitle}</MDTypography>
                        ),
                        type: (
                            <MDTypography>{item.isSell ? "SELL" : !item.isSell ? "PUBLIC" : "SELL"}</MDTypography>
                        ),

                        date: (
                            <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                        ),
                        action: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                <Link to={`/affadmeowsugarhgvhgvhgv/edit/${item.id}`}>
                                    <Tooltip title={'Edit'}>
                                        <MDButton color={'warning'}  style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                                    </Tooltip>
                                </Link>
                                <Tooltip title={'Delete'}>
                                    <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                                </Tooltip>
                            </MDTypography>
                        ),


                    }
                })

            }
        }
        setRows(arr)
    }, [posts, type])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <MDTypography variant="h6" color="white">
                                                Posts
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tabs
                                                value={type}
                                                onChange={handleChange}
                                                textColor="secondary"
                                                indicatorColor="secondary"
                                                aria-label="secondary tabs example"
                                                style={{margin: 15}}

                                            >
                                                <Tab  icon={<Icon>store_front</Icon>} iconPosition="start" value="All" label={`All (${posts ? posts.data.postsAll.count : null})`} />
                                                <Tab icon={<Icon>currency_bitcoin</Icon>} iconPosition="start" value="Sell" label={`Sell (${posts ? posts.data.postsSell.count : null})`} />
                                                <Tab icon={<Icon>groups</Icon>} iconPosition="start" value="Public" label={`Public (${posts ? posts.data.postsPublic.count : null})`} />
                                                <Tab icon={<Icon>discount</Icon>} iconPosition="start" value="Discount" label={`Discount (${posts ? posts.data.postsDiscount.rows.length : null})`} />


                                            </Tabs>
                                        </Grid>


                                    </Grid>
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Posts;
