import React from 'react';
import ListItem from "@mui/material/ListItem";
import {Author} from "../../tables/data/authorsTableData";
import team2 from "../../../assets/images/team-2.jpg";
import MDBadge from "../../../components/MDBadge";
import {setChat} from "../../../Redux/slices/chatSlice";
import {useDispatch, useSelector} from "react-redux";
import {useGetOnlineQuery} from "../../../Redux/slices/userApiSlice";
import {useGetMessagesQuery, useSandyMutation} from "../../../Redux/slices/messageApiSlice";

const Contact = ({chat}) => {
    const dispatch = useDispatch()

    const user = useSelector(state => state.auth.userInfo.user)
    const {data: onlines} = useGetOnlineQuery()
    const [sandy] = useSandyMutation()
    const onBig = async () => {
        dispatch(setChat(chat))
        const res = await sandy({receiver: chat.id})
    }
    return (
        <ListItem onClick={onBig} sx={{padding: '20px'}} button key="RemySharp">
            <Author  image={team2} client={chat.title} target={chat.target} />


            {onlines ?             <MDBadge badgeContent={onlines.data.includes(chat.users[0]) ? "Online" :  "Offline"} color={onlines.data.includes(chat.users[0]) ? "success" : "error"} variant="gradient" size="sm" />: null}
            {chat.status === "support" ?             <MDBadge badgeContent={"Support"} color={"info"} variant="gradient" size="sm" />: null}
            {chat.unreadMessages ? <MDBadge size={'sm'} color={'primary'} badgeContent={chat.unreadMessages}/> : null}

        </ListItem>
    );
};

export default Contact;