import { createSlice, configureStore } from '@reduxjs/toolkit';

//MOVEMENTS (doesn't work yet)
const initialState = {
    chatInfo: null,
};

const chatSlice = createSlice({
    name: 'chats',
    initialState: initialState,
    reducers: {
        setChat(state, action) {
            state.chatInfo = action.payload
        },

    },
});
export const {setChat} = chatSlice.actions;
export default chatSlice.reducer;
