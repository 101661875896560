

import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

export const buyedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBuyedPosts: builder.query({
            query: () => ({
                url: `/buyedPost/`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("NEW_BUY", (data) => {
                        const user = localStorage.getItem('userInfo')
                        const newUser = JSON.parse(user)
                        if (newUser.user.isAdmin){
                            infoToast(`New Buy!`)
                        }
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });
                    socket.on("READ_BUY", (data) => {
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['Buyed'],
        }),
        sendBuy: builder.mutation({
            query: (data) => {
                socket.emit("SEND_BUY", data)
            },
            invalidatesTags: ['Buyed'],
        }),


        setReadBuy: builder.mutation({
            query: (id) => {
                socket.emit("SET_READ_BUY", id)
            },
            invalidatesTags: ['Feedback'],
        }),
        completeBuyed: builder.mutation({
            query: (id) => ({
                url: `/buyedPost/activate/${id}`,
                method: 'POST',
            }),
            invalidatesTags: ['Buyed'],
        }),




    }),
});

export const {
    useGetBuyedPostsQuery,
    useSendBuyMutation,
    useCompleteBuyedMutation,
    useSetReadBuyMutation

} = buyedApiSlice;