import {configureStore, isRejectedWithValue} from '@reduxjs/toolkit';
import {apiSlice} from "./Redux/slices/apiSlice";
import authReducer from './Redux/slices/authSlice';
import {errorToast} from "./utils/toastify";
import chatSlice from "./Redux/slices/chatSlice";
import cartSlice from "./Redux/slices/cartSlice";

import messageSlice from "./Redux/slices/messageApiSlice"
import pathSlice from "./Redux/slices/pathSlice";
import searchSlice from "./Redux/slices/searchSlice";

const errorMiddleWare = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!')
        errorToast(action.payload.data.error)
    }
}

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        cart: cartSlice,
        auth: authReducer,
        chats: chatSlice,
        path: pathSlice,
        search: searchSlice


    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,



});

export default store;