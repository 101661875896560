import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

import { logout } from './authSlice'; // Import the logout action


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {

        const token = (getState()).auth.userInfo?.accessToken
        if (token) {
            headers.set('accessToken', `${token}`)
        }

        return headers
    },
})

export async function baseQueryWithAuth(args, api, extra) {
    const result = await baseQuery(args, api, extra);
    // if (result.error && result.error.status === 401) {
    //     api.dispatch(logout());
    // }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Feedback', 'User', 'Post', 'NewClient', 'Message', 'Chat', 'Target', 'Online', 'Crypto'],
    endpoints: (builder) => ({}),
});