/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";



// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/kitties.jpg";
import {useDispatch} from "react-redux";
import {useCreateClientMutation, useLoginClientMutation} from "../../../Redux/slices/userApiSlice";
import {setCredentials} from "../../../Redux/slices/authSlice";
import {errorToast, infoToast} from "../../../utils/toastify";
import {useUserOnlineMutation} from "../../../Redux/slices/messageApiSlice";
import Captcha from "demos-react-captcha";
import {Input, InputLabel, Select} from "@mui/material";
import {MenuItem} from "@material-ui/core";




function ClientRegister() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [createClient, {isLoading}] = useCreateClientMutation()
    const [userOnline] = useUserOnlineMutation()
    const [rememberMe, setRememberMe] = useState(false);
    const [captcha, setCaptcha] = useState(false)
    const [value, setValue] = useState("Telegram")
    const [matcha, setMatcha] = useState("")
    const [contact, setContact] = useState('')
    const onContact =  (e) => {
        let re = /["'<>/+={}~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            return
        } else{
            setContact(e.target.value)
        }
    }
    const onPassword = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            return
        } else{
            setPassword(e.target.value)
        }
    }



    const onCaptcha = (value) => {
        setCaptcha(value)
    }
    const isAlready =  localStorage.getItem('isAlready')
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (captcha){
                if (password !== matcha){
                    errorToast("Passwords don't match")
                    return
                }


                const res = await createClient({ username: email, password, contact, type: value })
                if (isAlready){
                    infoToast("The registration is successfull!")
                } else {
                    errorToast("You already registered")
                }
                localStorage.setItem('isAlready', 1)

                navigate('/client/login')
            } else{
                errorToast('You entered the wrong captcha');

            }
        } catch (err) {
            errorToast('error');
        }
    };
    const onEmail = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            return
        } else{
            setEmail(e.target.value)
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        REGISTRATION
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox onSubmit={submitHandler} component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="text"
                                     inputProps={{
                                         "minlength": "8"
                                     }}
                                     value={email} onChange={onEmail} label="Username" fullWidth />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password"
                                     inputProps={{
                                         "minlength": "8"
                                     }}
                                     value={password} onChange={onPassword} label="Password" fullWidth />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password"
                                     inputProps={{
                                         "minlength": "8"
                                     }}
                                     value={matcha} onChange={e => setMatcha(e.target.value)} label="Repeat your password" fullWidth />
                        </MDBox>
                        <Captcha
                            onChange={onCaptcha}
                            placeholder="Enter captcha" // optional
                            length={8} // default
                            char={(1, 2, 3, 4, 5, 6, 7, 8, 9)}
                        />
                        <div style={{marginBottom: 5, marginTop: 20}}>
                            <InputLabel id="demo-simple-select-label">Select Contact</InputLabel>
                            <Select
                                sx={{width: '70%', height: '50px'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value}
                                required
                                onChange={e => setValue(e.target.value)}

                            >
                                <MenuItem value={"Telegram"}>Telegram</MenuItem>
                                <MenuItem value={"Jabber"}>Jabber</MenuItem>
                                <MenuItem value={"TOX"}>TOX</MenuItem>
                                <MenuItem value={"Matrix"}>Matrix</MenuItem>

                            </Select>

                            <MDInput
                                style={{marginTop: 10}}
                                inputProps={{ maxLength: value === "Telegram" ? 30 : value==="TOX" ? 76 : value ==="Jabber" ? 60 : value==="Matrix"? 50 : 0, minLength: value === "Telegram" ? 4 : value==="TOX" ? 76 : value ==="Jabber" ? 5 : value === "Matrix" ? 10: 0  }}
                                value={contact}
                                onChange={onContact}
                                required
                                placeholder={value === "Telegram" ? "@nickname" : value === "Jabber" ? "nickname@example.com" : value ==="TOX" ? "09FJE9HDJ..." :  value==="Matrix" ? "@...:matrix.org" :"contact" }
                                label={value === "Telegram" ? "Telegram" : value === "Jabber" ? "Jabber" : value ==="TOX" ? "TOX" : "contact"}
                            />
                        </div>
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                Already have an account?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/client/login"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Sign in
                                </MDTypography>
                            </MDTypography>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" type={'submit'} color="info" fullWidth>
                                {isLoading? 'loading' : 'Register'}

                            </MDButton>
                        </MDBox>

                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default ClientRegister;
