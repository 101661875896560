/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import DeleteIcon from '@mui/icons-material/Delete';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import MDButton from "../../../components/MDButton";
import Tooltip from "@mui/material/Tooltip";

export default function data() {
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>

            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {email}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    return {
        columns: [
            { Header: "ID", accessor: "id", align: "left" },
            { Header: "Post", accessor: "postName", align: "left" },
            { Header: "User", accessor: "user", align: "center" },

            { Header: "price", accessor: "price", align: "left" },
            { Header: "isCompleted", accessor: "isCompleted", align: "center" },
            { Header: "Complete", accessor: "complete", align: "center" },


            { Header: "files", accessor: "files", align: "center" },

        ],

        rows: [
            {
                id: <MDTypography>1</MDTypography>,
                postName: (
                    <MDTypography>title</MDTypography>
                ),

                price: (
                    <MDTypography>1000$</MDTypography>
                ),
                user: (
                    <MDTypography>USA</MDTypography>
                ),
                revenue: (
                    <MDTypography>100.000$</MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

                        <Tooltip title={'Buy'}>
                            <MDButton color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                        </Tooltip>

                        <Tooltip title={'Target Info'}>
                            <MDButton color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>info</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),


            },{
                id: <MDTypography>1</MDTypography>,
                name: (
                    <MDTypography>title</MDTypography>
                ),

                price: (
                    <MDTypography>1000$</MDTypography>
                ),
                country: (
                    <MDTypography>USA</MDTypography>
                ),
                revenue: (
                    <MDTypography>100.000$</MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

                        <Tooltip title={'Buy'}>
                            <MDButton color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                        </Tooltip>

                        <Tooltip title={'Target Info'}>
                            <MDButton color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>info</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),


            },{
                id: <MDTypography>1</MDTypography>,
                name: (
                    <MDTypography>title</MDTypography>
                ),

                price: (
                    <MDTypography>1000$</MDTypography>
                ),
                country: (
                    <MDTypography>USA</MDTypography>
                ),
                revenue: (
                    <MDTypography>100.000$</MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

                        <Tooltip title={'Buy'}>
                            <MDButton color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                        </Tooltip>

                        <Tooltip title={'Target Info'}>
                            <MDButton color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>info</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),


            },{
                id: <MDTypography>1</MDTypography>,
                name: (
                    <MDTypography>title</MDTypography>
                ),

                price: (
                    <MDTypography>1000$</MDTypography>
                ),
                country: (
                    <MDTypography>USA</MDTypography>
                ),
                revenue: (
                    <MDTypography>100.000$</MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

                        <Tooltip title={'Buy'}>
                            <MDButton color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                        </Tooltip>

                        <Tooltip title={'Target Info'}>
                            <MDButton color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>info</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),


            },{
                id: <MDTypography>1</MDTypography>,
                postName: (
                    <MDTypography>title</MDTypography>
                ),

                price: (
                    <MDTypography>1000$</MDTypography>
                ),
                country: (
                    <MDTypography>USA</MDTypography>
                ),
                revenue: (
                    <MDTypography>100.000$</MDTypography>
                ),
                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">

                        <Tooltip title={'Buy'}>
                            <MDButton color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                        </Tooltip>

                        <Tooltip title={'Target Info'}>
                            <MDButton color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>info</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),


            },
        ],
    };
}
