import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch, useSelector} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation, useCreatePartnerMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {InputAdornment} from "@mui/material";
import {useEditTargetMutation} from "../../Redux/slices/targetApiSlice";


export default function ContactClient({contact}) {





    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const onCopy = () => {
        navigator.clipboard.writeText(contact);

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title={'Copy contact'}>
                <MDButton color={'info'} style={{marginRight: 15}} onClick={handleClickOpen}><Icon>file_copy</Icon></MDButton>
            </Tooltip>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <MDBox sx={style}>
                            <Grid mb={5} container>

                                <Grid container direction="row">
                                    <Grid item xs={8}>
                                        <MDTypography variant="h5">
                                            Client's contact
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"

                                    spacing={3}
                                >

                                    <Grid item mt={1} xs={12}>
                                        <MDInput
                                            value={contact}
                                            fullWidth
                                            margin="dense"
                                            type={'text'}
                                            variant="outlined"
                                            label="Contact"
                                            id="Link"
                                            InputProps={{endAdornment: (
                                                    <InputAdornment><Tooltip title={"Copy"}><MDButton onClick={onCopy} color={'info'}><Icon>content_copy</Icon></MDButton></Tooltip></InputAdornment>
                                                ), disabled: true}}
                                            required
                                        />
                                        <MDButton variant={'contained'} color={'info'} onClick={handleClose}>Close</MDButton>

                                    </Grid>


                                </Grid>


                            </Grid>

                    </MDBox>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}