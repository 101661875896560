/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import paymentsTableData from "./data/paymentsTableData";
import {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePasswordPartner from "../../components/Modals/UpdatePasswordPartner";
import {useGetTargetsByIdQuery} from "../../Redux/slices/targetApiSlice";
import {useSelector} from "react-redux";
import MDBadge from "../../components/MDBadge";
import TargetInfo from "../../components/Modals/TargetInfo";
import DeclineTarget from "../../components/Modals/DeclineTarget";
import ApproveTarget from "../../components/Modals/ApproveTarget";
import TargetInfoPartner from "../../components/Modals/TargetInfoPartner";
import {formatter} from "../../utils/fornatter";
import {useGetPaymentsQuery} from "../../Redux/slices/cryptoApiSlice";

function Payments() {
    const [open, setOpen] = useState(false)
    const [rows, setRows] = useState([])
    const partnerId = useSelector(state => state.auth.userInfo.user.id)
    const {data: payments} = useGetPaymentsQuery()
    const { columns } = paymentsTableData();
    useEffect(() => {
        let arr = []
        if (payments){
            arr = payments.data.map(item => {
                return              {
                    id: <MDTypography>{item.id}</MDTypography>,
                    amount: (
                        <MDTypography>{item.amount}</MDTypography>
                    ),
                    user: (
                        <MDTypography>{item.user}</MDTypography>
                    ),


                    status: (
                        <MDBox ml={-1}>
                            <MDBadge
                                badgeContent={item.status}
                                color={item.status === "awaiting" ? "warning" : item.status === "completed" ? "success" : "info"}
                                variant="gradient" size="sm" />
                        </MDBox>
                    ),
                    date: (
                        <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                    ),





                }
            })
        }
        setRows(arr)
    }, [payments])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Payments
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Payments;
