import React, {useEffect, useState} from 'react';
import {useGetPostsQuery} from "../../Redux/slices/postApiSlice";
import Post from "../../components/post";
import {Hourglass} from "react-loader-spinner";
import {Pagination} from "@mui/material";
import {useLocation} from "react-router-dom";
import Box from "@mui/material/Box";

const PostList = () => {
    const [loading, setLoading] = useState(false);
    const search = useLocation().search
    const searchKey = new URLSearchParams(search).get('search')
    // let [image, setImage] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [cardsPerPage] = useState(10);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    // console.log(cardsPerPage, movieCard.length, paginate);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const {data: posts, isLoading} = useGetPostsQuery({currentPage, searchKey})
    const [arr, setArr] = useState([])
    useEffect(() => {
        if (posts){
            const final = posts.data.posts.map(item => {
                const created = new Date(item.timer)

                const rightNow = new Date()
                if (rightNow.getTime() > created.getTime()){
                    return {...item, timeout: true}
                } else{
                    return {...item, timeout: false}

                }
            })
            setArr(final)
        }
    }, [posts])

    return (
        <>

            {arr.length === 0 ? "Post not found" : posts ? arr.map(item => {
                return <Post item={item}/>
            }) : <div style={{position: "absolute", top: '40%', left: '45%'}}>
                <Hourglass

                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    colors={['#306cce', '#72a1ed']}
                />

            </div>
            }
            <div style={{display: 'grid', justifyContent: 'center'}}>
                {posts ? <Pagination style={{marginTop: '80px'}} color={'primary'} count={posts.data.pageCount} page={currentPage} onChange={handleChange} />: null}

            </div>
        </>
    );
};

export default PostList;