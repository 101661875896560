/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import newclientTableData from "./data/newclientTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {
    useDeleteNewClientMutation,
    useGetNewClientsQuery,
    useSetReadNewClientMutation
} from "../../Redux/slices/newClientApiSlice";
import {errorToast, infoToast} from "../../utils/toastify";

function NewClient() {
    const [open, setOpen] = useState(false)
    const [deleteNewClient ] = useDeleteNewClientMutation()
    const { columns } = newclientTableData(setOpen);
    const [rows, setRows] = useState([])
    const {data: clients} = useGetNewClientsQuery()
    const [setReadNewClient] = useSetReadNewClientMutation()
    const formatter = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timezone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
    });
    const onDelete = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await deleteNewClient(id)
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }
    useEffect(() => {
        if (clients){
            clients.data.newClients.forEach(async (item) => {
                if (item.isNew){
                    const res1 = await setReadNewClient(item.id)
                }
            })
        }
    }, [clients])
    useEffect(() => {
        let arr = []
        if (clients){
            arr = clients.data.newClients.map(item => {
                return  {
                    id: <MDTypography>{item.id}</MDTypography>,
                    type: (
                        <MDTypography>{item.contactType}</MDTypography>
                    ),
                    contact: (
                        <MDTypography>{item.contact}</MDTypography>
                    ),


                    date: (
                        <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                    ),
                    action: (
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                    ),


                }
            })
        }
        setRows(arr)
    }, [clients])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    New Clients
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default NewClient;
