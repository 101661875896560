import React, {useState} from 'react';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {Stack, TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Card from "@mui/material/Card";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import MDButton from "../../components/MDButton";
import {useCreateTargetMutation, useSendTargetMutation} from "../../Redux/slices/targetApiSlice";
import {useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
const TargetCreate = () => {
    const [createTarget, {isLoading}] = useCreateTargetMutation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [link, setLink] = useState('')
    const [zoom, setZoom] = useState('')
    const [site, setSite] = useState('')
    const partnerId = useSelector(state => state.auth.userInfo.user.id)
    const [has, setHas] = useState(false)
    const [sendTarget] = useSendTargetMutation()
    const onTitle = (e) => {
        let re = /["'_@.<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setTitle(e.target.value)
        }
    }
    const onDescription = (e) => {
        let re = /["'<>/+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setDescription(e.target.value)
        }
    }
    const onZoom = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setZoom(e.target.value)
        }
    }
    const onReason = (e) => {
        let re = /["'<>+=~`./?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setReason(e.target.value)
        }
    }
    const onLink = (e) => {
        let re = /[<>]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setLink(e.target.value)
        }
    }
    const onSite = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setSite(e.target.value)
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await createTarget({title, price, description, link, zoomInfo: zoom, site, partnerId})
            const res1 = await sendTarget()
            infoToast("Success")
        } catch (err) {
            errorToast(err.message)
        }
    }
    return (

            <>
                <DashboardLayout>
                    <DashboardNavbar/>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 col-md-10 col-12 m-auto">

                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Targets
                                        </MDTypography>
                                    </MDBox>
                                    <div style={{padding: '5%'}} className="card-body">

                                        <form onSubmit={onSubmit}>

                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="Title"
                                                value={title}
                                                onChange={onTitle}
                                                fullWidth
                                                required
                                                sx={{mb: 4}}
                                            />

                                            <MDTypography>Description</MDTypography>
                                            <TextareaAutosize
                                                required
                                                style={{width: '100%'}}
                                                maxRows={30}
                                                value={description}
                                                onChange={onDescription}
                                                placeholder={"1.Darpa.zip developer\n" +
                                                    "2.Budget.zip finance\n" +
                                                    "3.Users.zip employer\n" +
                                                    "4.2426.zip new documents 2024-2026\n" +
                                                    "5.list.txt filelist\n" +
                                                    "and etc..."}/>
                                            <TextField
                                                type="number"
                                                variant='outlined'
                                                color='secondary'
                                                label="Price"
                                                inputProps={{
                                                    "min": "1"
                                                }}
                                                value={price}
                                                onChange={e => setPrice(e.target.value)}
                                                required
                                                fullWidth
                                                sx={{mb: 4, mt: 5}}
                                            />
                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="Link to files (login, password)"
                                                value={link}
                                                onChange={onLink}
                                                required
                                                fullWidth
                                                sx={{mb: 4}}
                                            />
                                            <TextField
                                                value={zoom}
                                                onChange={onZoom}
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="ZoomInfo"
                                                required
                                                fullWidth
                                                sx={{mb: 4}}
                                            />
                                            <TextField
                                                type="text"
                                                variant='outlined'
                                                color='secondary'
                                                label="Site of Target"
                                                required
                                                fullWidth
                                                sx={{mb: 4}}
                                                value={site}
                                                onChange={onSite}
                                            />

                                            <MDButton style={{marginTop: '5'}} variant="contained" color={'info'} type="submit">{isLoading ? "Loading" : "Submit"}</MDButton>
                                        </form>
                                    </div>
                                </Card>
                            </div>
                        </div>

                    </div>
                </DashboardLayout>
            </>

    );
};

export default TargetCreate;