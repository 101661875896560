import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Input, InputLabel, Select} from "@mui/material";
import {MenuItem} from "@material-ui/core";
import {useState} from "react";
import {useCreateNewClientMutation, useSendNewClientMutation} from "../../../Redux/slices/newClientApiSlice";
import {errorToast, infoToast} from "../../../utils/toastify";
import MDInput from "../../../components/MDInput";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};

export default function BuyModal() {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = useState("Telegram")
    const [contact, setContact] = useState('')
    const [has,setHas] = useState(false)
    const [sendNewClient] = useSendNewClientMutation()

    const onContact =  (e) => {
        let re = /["'<>/+={}~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setContact(e.target.value)
        }
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [createNewClient] = useCreateNewClientMutation()
    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await createNewClient({contactType: value, contact}).unwrap()
            const res1 = await sendNewClient()
            setContact('')
            setOpen(false)
            infoToast("Wait, we will contact you soon")
        } catch (err) {
            errorToast(err.error)
        }
    }
    return (
        <div>
            <button onClick={handleOpen}
                className="btn bg-gradient-info mb-0 mt-lg-auto w-100"
                type="button" name="button">Buy
            </button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form onSubmit={onSubmit}>
                            <Typography variant={'h4'} mb={5}>Your contacts</Typography>
                            <Grid spacing={3} container>
                                <Grid item xs={12}>
                                    <InputLabel id="demo-simple-select-label">Contact</InputLabel>
                                    <Select
                                        sx={{width: '70%'}}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={value}
                                        required
                                        onChange={e => setValue(e.target.value)}

                                    >
                                        <MenuItem value={"Telegram"}>Telegram</MenuItem>
                                        <MenuItem value={"Jabber"}>Jabber</MenuItem>
                                        <MenuItem value={"TOX"}>TOX</MenuItem>
                                        <MenuItem value={"Matrix"}>Matrix</MenuItem>

                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        inputProps={{ maxLength: value === "Telegram" ? 30 : value==="TOX" ? 76 : value ==="Jabber" ? 60 : value==="Matrix"? 50 : 0, minLength: value === "Telegram" ? 4 : value==="TOX" ? 76 : value ==="Jabber" ? 5 : value === "Matrix" ? 10: 0  }}
                                        variant={'contained'}
                                        value={contact}
                                        onChange={onContact}
                                        required
                                        placeholder={value === "Telegram" ? "@nickname" : value === "Jabber" ? "nickname@example.com" : value ==="TOX" ? "09FJE9HDJ..." :  value==="Matrix" ? "@...:matrix.org" :"contact" }
                                        label={value === "Telegram" ? "Telegram" : value === "Jabber" ? "Jabber" : value ==="TOX" ? "TOX" : "contact"}/>
                                </Grid>
                                <Grid item xs={12}>

                                <Button type={'submit'} style={{margin: '0 auto', width: '50%'}} variant={'outlined'}>Send</Button>
                                </Grid>

                                </Grid>



                        </form>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}