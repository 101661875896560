import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation, useCreatePartnerMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {Input, InputAdornment} from "@mui/material";
import {useEditTargetMutation} from "../../Redux/slices/targetApiSlice";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


export default function InfoPost({links}) {





    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [username, setUsername] = useState('')
    const onCopy = (item) => {
        navigator.clipboard.writeText(item);

    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title={'Download'}>
                <Button color={'info'} variant={'contained'} style={{marginRight: 15}} onClick={handleClickOpen}>Download</Button>
            </Tooltip>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form>
                            <Grid mb={5} container>

                                <Grid container direction="row">
                                    <Grid item xs={8}>
                                        <Typography variant="h5">
                                            Links
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"

                                    spacing={3}
                                >

                                    {links.map(item => {
                                        return (
                                            <Grid item mt={1} xs={12}>
                                                <TextField
                                                    value={item }

                                                    fullWidth
                                                    margin="dense"
                                                    type={'text'}
                                                    variant="outlined"
                                                    label="Link"
                                                    disabled
                                                    id="Link"
                                                    InputProps={{endAdornment: (
                                                            <InputAdornment><Tooltip title={"Copy"}><Button onClick={(() => onCopy(item))} color={'info'}><Icon>content_copy</Icon></Button></Tooltip></InputAdornment>
                                                        )}}
                                                    required
                                                />

                                            </Grid>
                                        )
                                    })}



                                </Grid>


                            </Grid>

                        </form>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}