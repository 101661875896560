import { createSlice } from '@reduxjs/toolkit';
import {io} from "socket.io-client";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

const initialState = {
    userInfo: localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))
        : {},
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.userInfo = action.payload;
            localStorage.setItem('userInfo', JSON.stringify(action.payload));
        },
        logout: (state, action) => {
            if (state.userInfo){
                socket.emit("USER_OFFLINE", state.userInfo?.user?.id)
                state.userInfo = null;


                const info = localStorage.getItem('userInfo')
                const final = JSON.parse(info)
                const user = final.user

                if (user.isClient){
                    window.location.href = '/client/login'
                } else if (user.isAdmin){
                    window.location.href = '/affadmeowsugarhgvhgvhgv/login'
                } else if (user.isPartner){
                    window.location.href = '/partner/login'
                }
                localStorage.clear();

            }
        },
    },
});

export const { setCredentials, logout } = authSlice.actions;


export default authSlice.reducer;