import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});
export const targetApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createTarget: builder.mutation({
            query: (data) => ({
                url: `/target/createTarget`,
                method: "POST",
                body: data
            }),
            keepUnusedDataFor: 5,
            invalidatesTags: ['Target'],
        }),
        sendTarget: builder.mutation({
            query: () => {
                socket.emit("SEND_TARGET")
            },
            invalidatesTags: ['Target'],
        }),
        setReadTarget: builder.mutation({
            query: (id) => {
                socket.emit("SET_READ_TARGET", id)
            },
            invalidatesTags: ['Target'],
        }),

        declineTarget: builder.mutation({
            query: (data) => {
                socket.emit("DECLINE_TARGET", data)
            },
            invalidatesTags: ['Target'],
        }),
        approveTarget: builder.mutation({
            query: (data) => ({
                url: `/target/approveTarget`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Target'],
        }),
        editTarget: builder.mutation({
            query: (data) => {
                socket.emit("EDIT_TARGET", data)
            },
            invalidatesTags: ['Target'],
        }),
        editTargetByPartner: builder.mutation({
            query: (data) => ({
                url: `/target/editTargetPartner`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Target'],
        }),
        getTargets: builder.query({
            query: () => ({
                url: `/target/getTargets`,

            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("RECEIVE_TARGET", (data) => {
                        infoToast(`New Target!`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            providesTags: ['Target'],
        }),
        getTargetsById: builder.query({
            query: (partnerId) => ({
                url: `/target/getTargetsById/${partnerId}`,

            }),
            providesTags: ['Target'],
        }),
        deleteTarget: builder.mutation({
            query: (id) => ({
                url: `/target/deleteTarget/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Target'],
        }),

    }),
});

export const {
    useGetTargetsQuery,
    useGetTargetsByIdQuery,
    useDeclineTargetMutation,
    useApproveTargetMutation,
    useEditTargetMutation,
    useEditTargetByPartnerMutation,
    useDeleteTargetMutation,
    useCreateTargetMutation,
    useSetReadTargetMutation,
    useSendTargetMutation

} = targetApiSlice;