
import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

export const cryptoApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPayments: builder.query({
            query: () => ({
                url: `/crypto/getPayments`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("NEW_PAYMENT", (data) => {
                        infoToast(`New Payment!`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['Crypto'],
        }),
        getPayment: builder.query({
            query: (address) => ({
                url: `/crypto/${address}`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("COMPLETED_PAYMENT", (data) => {
                        console.log('good')
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['Crypto'],
        }),



        createPayment: builder.mutation({
            query: (data) => ({
                url: `/crypto/create`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Crypto'],
        }),
        nullBalance: builder.mutation({
            query: (id) => ({
                url: `/crypto/nullBalance/${id}`,
                method: 'PATCH',
            }),
            invalidatesTags: ['User'],
        }),
        getUSD: builder.query({
            query: (data) => ({
                url: `/crypto/usd`,
            }),
            invalidatesTags: ['Crypto'],
        }),



    }),
});

export const {
    useCreatePaymentMutation,
    useGetPaymentsQuery,
    useGetPaymentQuery,
    useGetUSDQuery,
    useNullBalanceMutation
} = cryptoApiSlice;