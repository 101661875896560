/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import feedbackTableData from "./data/feedbackTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import {useEffect, useState} from "react";
import CreateClient from "../../components/Modals/CreateClient";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePassword from "../../components/Modals/UpdatePassword";
import {useDeleteUserMutation, useGetClientsQuery} from "../../Redux/slices/userApiSlice";
import {errorToast, infoToast} from "../../utils/toastify";
import {
    useDeleteFeedbackMutation,
    useGetFeedbacksQuery,
    useSetReadFeedbackMutation
} from "../../Redux/slices/feedbackApiSlice";
import {formatter} from "../../utils/fornatter";

function Feedback() {
    const {data: feedbacks} = useGetFeedbacksQuery()
    const [setReadFeedback] = useSetReadFeedbackMutation()
    const [deleteFeedback, {isLoading}] = useDeleteFeedbackMutation()
    const { columns } = feedbackTableData();
    const [rows, setRows] = useState([])

    const onDelete = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await deleteFeedback(id)
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }
    useEffect(() => {
        if (feedbacks){
            feedbacks.data.feedbacks.forEach(async (item) => {
                if (item.isNew){
                    const res1 = await setReadFeedback(item.id)
                }
            })
        }
    }, [feedbacks])

    useEffect(() => {
        let arr = []
        if (feedbacks){
            arr = feedbacks.data.feedbacks.map(item => {
                return  {
                    id: <MDTypography>{item.id}</MDTypography>,
                    title: (
                        <MDTypography>{item.title}</MDTypography>
                    ),
                    email: (
                        <MDTypography>{item.email}</MDTypography>
                    ),
                    content: (
                        <MDTypography component={'p'}>{item.content}</MDTypography>
                    ),

                    date: (
                        <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                    ),
                    action: (
                        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                            <Tooltip title={'Delete'}>
                                <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                            </Tooltip>
                        </MDTypography>
                    ),


                }
            })
        }
        setRows(arr)
    }, [feedbacks])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDTypography variant="h6" color="white">
                                            Feedbacks
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                {feedbacks ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                ) : "loading"}
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Feedback;
