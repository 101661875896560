import React from 'react';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {useSelector} from "react-redux";
import {
    useApproveChatMutation,
    useDeclineChatMutation,
    useDeleteChatMutation
} from "../../../Redux/slices/chatApiSlice";
import {errorToast, infoToast} from "../../../utils/toastify";
import {useGetMessagesQuery} from "../../../Redux/slices/messageApiSlice";

const ChatHeader = () => {
    const user = useSelector(state => state.auth.userInfo.user)

    const chat = useSelector(state => state.chats.chatInfo)
    console.log(chat)
    const [approveChat] = useApproveChatMutation()
    const [declineChat] = useDeclineChatMutation()
    const [deleteChat] = useDeleteChatMutation()

    const onDelete = async () => {
        try {
            const res = await deleteChat(chat.id)
            infoToast("Chat Deleted")
        } catch (e) {
            errorToast(e.message)
        }
    }
    const onApprove = async () => {
        try {
            const res = await approveChat(chat.id)
            infoToast("Chat Approved")
        } catch (e) {
            errorToast(e.message)
        }
    }
    const onDecline = async () => {
        try {
            const res = await declineChat(chat.id)
            infoToast("Chat Declined")
        } catch (e) {
            errorToast(e.message)
        }
    }

    return (
        <MDBox
            mx={2}
            mt={-3}
            mb={3}
            py={3}
            px={2}
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
        >
            <Grid container>
                <Grid item xs={3}>
                    <MDTypography variant="h6" color="white">
                        Chat
                    </MDTypography>
                </Grid>
                <Grid item xs={3}>
                    <MDTypography variant="h6" color="white">
                        Client: {chat.title}
                    </MDTypography>
                </Grid>
                <Grid item xs={3}>
                    <MDTypography variant="h6" color="white">
                        {chat.target}
                    </MDTypography>
                </Grid>
                {user.isAdmin ? (
                    <>
                        <Grid item xs={1}>
                            <Tooltip title={'Delete Chat'}>
                                <MDButton variant={'contained'} onClick={onDelete} color={'error'}><Icon>delete</Icon></MDButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={'Decline Chat'}>
                                <MDButton variant={'contained'} onClick={onDecline} color={'error'}><Icon>thumb_down_alt</Icon></MDButton>
                            </Tooltip>
                        </Grid>

                    </>
                ): null}
            </Grid>
        </MDBox>
    );
};

export default ChatHeader;