import React from 'react';
import '../../assets/css/material-dashboard.css'
import Post from "../../components/post";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Box from "@mui/material/Box";
import {Pagination} from "@mui/material";
import Badge from "@mui/material/Badge";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import ResponsiveAppBar from "../../components/navbar";
import themeDark from "../../assets/theme-dark";
import theme from "../../assets/theme";
import {ThemeProvider} from "@mui/material/styles";
import PostList from "../postList";
import Card from "@mui/material/Card";


const Index = () => {
    return (
        <>



            <Box variant="contained"
            bgColor="transparent"
            color="dark"
            opacity={1}
            borderRadius="none"
            shadow="none"
            coloredShadow="none"
            px={30}
            style={{margin: '0 auto'}}
            >


                <div className="row mt-5">
                    <ResponsiveAppBar/>
                    <Card
                        style={{marginTop: 30, fontWeight: 500, fontSize: 25, textAlign: 'center', border: 50}}
                        variant={'elevation'}
                    >
                        Dear Valued Clients,

                        We have exciting news! Our service now allows you to purchase any company files that interest you. Registration is open!

                    </Card>
                    <PostList/>
                </div>
                <Box mt={10} mb={10} style={{alignContent: 'center', marginBottom: 30, marginLeft: '40%'}}>

                </Box>
            </Box>


        </>
    );
};

export default Index;