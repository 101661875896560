import React from 'react';
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Message from "./message";
import List from "@mui/material/List";
import {useGetMessagesQuery} from "../../../Redux/slices/messageApiSlice";
import {useSelector} from "react-redux";
const useStyles = makeStyles({

    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '80vh'
    },


    messageArea: {
        height: '70vh',
        overflowY: 'auto',
        marginLeft: '5%',
        marginRight: '5%'

    }
});

const MessageList = () => {
    const classes = useStyles();
    const user = useSelector(state => state.auth.userInfo.user)
    const chat = useSelector(state => state.chats.chatInfo)
    const {data: messages, isLoading} = useGetMessagesQuery({userId: user.id, id: chat.id})

    return (
            <MDBox className={classes.messageArea}>
                <List>
                    {isLoading ? "Loading" : (
                        messages.data.map(item => {
                            return <Message message={item}/>
                        })
                    )}
                </List>
            </MDBox>
    );
};

export default MessageList;