
import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

export const newClientApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNewClients: builder.query({
            query: () => ({
                url: `/client/getNewClients`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("RECEIVE_NEWCLIENT", (data) => {
                        infoToast(`New Client!`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['NewClient'],
        }),
        sendNewClient: builder.mutation({
            query: () => {
                socket.emit("SEND_NEWCLIENT")
            },
            invalidatesTags: ['NewClient'],
        }),
        setReadNewClient: builder.mutation({
            query: (id) => {
                socket.emit("SET_READ_NEWCLIENT", id)
            },
            invalidatesTags: ['NewClient'],
        }),


        createNewClient: builder.mutation({
            query: (data) => ({
                url: `/client/createNewClient`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['NewClient'],
        }),
        deleteNewClient: builder.mutation({
            query: (id) => ({
                url: `/client/deleteNew/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['NewClient'],
        }),


    }),
});

export const {
    useCreateNewClientMutation,
    useDeleteNewClientMutation,
    useGetNewClientsQuery,
    useSendNewClientMutation,
    useSetReadNewClientMutation
} = newClientApiSlice;