 import React from 'react';
import img from '../../assets/images/bg-profile.jpeg'
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {Link} from "react-router-dom";
import MyTimer from "../Timer";
import Typography from "@mui/material/Typography";
 import Tooltip from "@mui/material/Tooltip";
 import Box from "@mui/material/Box";
 import Grid from "@mui/material/Grid";
const Post = ({item}) => {
    return (
        <>

            <div className="col-lg-4 col-md-6" style={{marginTop: 200}}>
                <div className="card" style={{width: 410, height: 700}} data-animation="true">
                    <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                        <Link to={`/product/${item.id}`} className="d-block blur-shadow-image">


                            <img
                                style={{objectFit: 'contain', width: 380}}
                                src={`${process.env.REACT_APP_BASE_URL}${item.images[0]}`}
                                 alt="img-blur-shadow"
                                 className="img-fluid shadow border-radius-lg"/>
                        </Link>
                        <div className="colored-shadow"
                        ></div>
                    </div>
                    <div className="card-body text-center">
                        <Link to={`/product/${item.id}`}>
                            <Button variant={'contained'}>
                                {item.isSell ? (
                                        <>
                                            <Icon>shopping_cart</Icon>
                                            BUY
                                        </>
                                ) : (
                                    <>
                                        <Icon>remove_red_eye</Icon>
                                        OPEN
                                    </>
                                )}
                            </Button>
                        </Link>
                        <Link to={`/product/${item.id}`}>

                        <h5 className="font-weight-normal mt-3">
                            <a href="javascript:;">{item.title}</a>
                        </h5>
                        <Typography style={{width: '100%', background: 'rgb(24, 114, 206)', color: 'white', borderRadius: '5px'}} variant={'h6'}>{item.subtitle}</Typography>
                        </Link>
                    </div>
                    {item.isSell ? (
                            <>
                                <hr className="dark horizontal my-0"/>
                                <div className="card-footer d-flex">
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Link to={`/product/${item.id}`}>
                                                <Tooltip title={'In several hands'}>
                                                    <Button style={{marginBottom:10,textDecoration: item.discount ? 'line-through #ea3201 2px' : 'none'}} variant={'contained'}>
                                                        {item.price}$
                                                    </Button>
                                                </Tooltip>
                                            </Link>

                                            <Link to={`/product/${item.id}`}>
                                                <Tooltip title={'In one hand'}>
                                                    <Button style={{marginLeft: 20, marginBottom:10, textDecoration: item.onediscount ? 'line-through #ea3201 2px' : 'none'}} variant={'contained'}>
                                                        <div  className={'dis'}>
                                                            {item.oneprice}$
                                                        </div>
                                                    </Button>
                                                </Tooltip>
                                            </Link>
                                        </Grid>
                                        {item.discount && item.onediscount ? (
                                            <Grid item={9}>
                                                <Link to={`/product/${item.id}`}>
                                                    <Tooltip title={'In several hands'}>
                                                        <Button variant={'contained'}>
                                                            {item.discount}$
                                                        </Button>
                                                    </Tooltip>
                                                </Link>

                                                <Link to={`/product/${item.id}`}>
                                                    <Tooltip title={'In one hand'}>
                                                        <Button style={{marginLeft: 20, textDecoration: 'none'}} variant={'contained'}>
                                                            <div  className={'dis'}>
                                                                {item.onediscount}$
                                                            </div>
                                                        </Button>
                                                    </Tooltip>
                                                </Link>
                                            </Grid>
                                        ): null}


                                    </Grid>



                                    <i className="material-icons position-relative ms-auto text-lg me-1 my-auto">remove_red_eye</i>
                                    <p className="text-sm my-auto">{item.views}</p>
                                </div>
                            </>
                    ) : (
                        <>
                            <hr className="dark horizontal my-0"/>

                            <div className="card-footer d-flex">
                                {!item.timeout ? (
                                    <MyTimer expiryTimestamp={Date.parse(item.timer)}/>
                                ) : (
                                    <p className="font-weight-normal my-auto">Ready</p>
                                )}
                                <i className="material-icons position-relative ms-auto text-lg me-1 my-auto">remove_red_eye</i>
                                <p className="text-sm my-auto">{item.views}</p>
                            </div>
                        </>
                        )}
                </div>
            </div>
        </>
    );
};

export default Post;