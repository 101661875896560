import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";

import { useMaterialUIController, setLayout } from "context";
import {useSelector} from "react-redux";
import {useJoinChatMutation, useUserOnlineMutation} from "../../../Redux/slices/messageApiSlice";
import {useGetChatsByIdQuery} from "../../../Redux/slices/chatApiSlice";

function DashboardLayout({ children }) {
    const user = useSelector(state => state.auth.userInfo.user)
    const [joinChat] = useJoinChatMutation()

    const {data: chats, isLoading} = useGetChatsByIdQuery({id: user.id})
    useEffect(() => {
        if (chats){

            const final = chats.data.map(item => {
                const res = joinChat(item.id)
            })
        }
    }, [chats, user])
    const [onlineUser] = useUserOnlineMutation()
    useEffect( () => {
        try {
            if (user){
                const res =  onlineUser({userId: user?.id})

            }
        } catch (e) {

        }
    }, [user])
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
