import React from 'react';
import IconButton from "@mui/material/IconButton";
import {navbarIconButton} from "./Navbars/DashboardNavbar/styles";
import Icon from "@mui/material/Icon";
import {logout} from "../Redux/slices/authSlice";
import {useDispatch} from "react-redux";

const Logout = () => {
    const dispatch = useDispatch()
    const onLog = () => {
        dispatch(logout())
    }
    return (
        <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"

        >
            <Icon onClick={onLog}>logout</Icon>
        </IconButton>
    );
};

export default Logout;