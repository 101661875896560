import { apiSlice } from './apiSlice';
import {infoToast} from "../../utils/toastify";
import {io} from "socket.io-client";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

export const feedbackApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFeedbacks: builder.query({
            query: () => ({
                url: `/feedback/getFeedbacks`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("RECEIVE_FEEDBACK", (data) => {
                        infoToast(`New Feedback`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });
                    socket.on("GOT_READ_FEEDBACK", (data) => {
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['Feedback'],
        }),

        deleteFeedback: builder.mutation({
            query: (id) => ({
                url: `/feedback/delete/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Feedback'],
        }),
        sendFeedback: builder.mutation({
            query: () => {
                socket.emit("SEND_FEEDBACK")
            },
            invalidatesTags: ['Feedback'],
        }),
        setReadFeedback: builder.mutation({
            query: (id) => {
                socket.emit("SET_READ_FEEDBACK", id)
            },
            invalidatesTags: ['Feedback'],
        }),
        createFeedback: builder.mutation({
            query: (data) => ({
                url: `/feedback/createFeedback`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Feedback'],
        }),

    }),
});

export const {
    useGetFeedbacksQuery,
    useCreateFeedbackMutation,
    useDeleteFeedbackMutation,
    useSendFeedbackMutation,
    useSetReadFeedbackMutation
} = feedbackApiSlice;