import React, { useState } from 'react'
import {BiSearchAlt2} from 'react-icons/bi'
import {  useNavigate } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import MenuIcon from "@mui/icons-material/Menu";
import {InputBase} from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

function SearchIcon() {
    return null;
}

const SearchForm = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [has, setHas] = useState(false)
    const navigate =useNavigate()
    const onSearch = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setSearchTerm(e.target.value)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault() ;
        if(searchTerm){
            navigate(`/?search=${searchTerm}`)
        }

        setSearchTerm("")
    }


    return (

        <Paper
            onSubmit={handleSubmit}
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >

            <InputBase
                sx={{ ml: 1, flex: 1, width: '200px' }}
                placeholder="Search..."
                value={searchTerm}
                onChange={onSearch}
                inputProps={{ 'aria-label': 'search google maps', 'required': 'true' }}
            />

            <Divider  sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton  type="submit" sx={{ p: '10px' }} aria-label="search">
                <Icon >search</Icon>
            </IconButton>
        </Paper>
    )
}

export default SearchForm