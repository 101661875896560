import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});
export const chatApiSlice = apiSlice.injectEndpoints({
        endpoints: (builder) => ({
            getChatsById: builder.query({
                query: (data) => ({
                    url: `/chat/getChats/?id=${data.id || ''}&type=${data.type || ''}`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on('RECEIVE_CHAT', async () => {
                        const token = localStorage.getItem('userInfo')
                        const newToken = JSON.parse(token)
                        // Повторно запрашиваем данные вручную с помощью fetch
                        const response = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/chat/getChats/?id=${arg.id || ''}&type=${arg.type || ''}`,
                            {
                                headers: {
                                    accesstoken: `${newToken.accessToken}`, // Добавляем токен в заголовок
                                },
                            }
                    );
                        const data = await response.json();
                        console.log(data.totalUnreadMessages)

                        // Обновляем кэш вручную
                        updateCachedData((draft) => {
                            draft.totalUnreadMessages = JSON.parse(JSON.stringify(data.totalUnreadMessages));
                        });
                    });
                    socket.on('RECEIVE_READ', async () => {

                        const token = localStorage.getItem('userInfo')
                        const newToken = JSON.parse(token)
                        // Повторно запрашиваем данные вручную с помощью fetch
                        const response = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/chat/getChats/?id=${arg.id || ''}&type=${arg.type || ''}`,
                            {
                                headers: {
                                    accesstoken: `${newToken.accessToken}`, // Добавляем токен в заголовок
                                },
                            }
                    );
                        const data = await response.json();
                        console.log(data.totalUnreadMessages)
                        // Обновляем кэш вручную
                        updateCachedData((draft) => {
                            draft.totalUnreadMessages = JSON.parse(JSON.stringify(data.totalUnreadMessages));
                        });
                    });
                    socket.on('RECEIVE_MESSAGE2', async () => {

                        const token = localStorage.getItem('userInfo')
                        const newToken = JSON.parse(token)
                        // Повторно запрашиваем данные вручную с помощью fetch
                        const response = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/chat/getChats/?id=${arg.id || ''}&type=${arg.type || ''}`,
                            {
                                headers: {
                                    accesstoken: `${newToken.accessToken}`, // Добавляем токен в заголовок
                                },
                            }
                        );
                        const data = await response.json();
                        console.log(data.totalUnreadMessages)
                        // Обновляем кэш вручную
                        updateCachedData((draft) => {
                            draft.totalUnreadMessages = JSON.parse(JSON.stringify(data.totalUnreadMessages));
                        });
                    });



                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            providesTags: ['Chat'],
        }),

        deleteChat: builder.mutation({
            query: (id) => ({
                url: `/chat/deleteChat/${id}`,
                method: 'DELETE',
        }),
        invalidatesTags: ['Chat'],
    }),
    declineChat: builder.mutation({
    query: (id) => ({
        url: `/chat/declineChat/${id}`,
        method: 'PUT',

}),
    invalidatesTags: ['Chat'],
}),
approveChat: builder.mutation({
    query: (id) => ({
        url: `/chat/approveChat/${id}`,
        method: 'PUT',

}),
    invalidatesTags: ['Chat'],
}),
createChat: builder.mutation({
    query: (data) => ({
        url: `/chat/createChat`,
        method: 'POST',
        body: data
    }),
    invalidatesTags: ['Chat'],
}),
    createPartnerChat: builder.mutation({
    query: (data) => ({
        url:`/chat/createPartnerChat`,
        method: 'POST',
        body: data
    }),
    invalidatesTags: ['Chat'],
}),
    addVisit: builder.mutation({
    query: (id) => ({
        url: `/chat/visit/${id}`,
        method: 'PUT',

}),
    invalidatesTags: ['Chat'],
}),


}),
});

export const {
    useGetChatsByIdQuery,
    useCreateChatMutation,
    useCreatePartnerChatMutation,
    useDeclineChatMutation,
    useApproveChatMutation,
    useDeleteChatMutation,
    useAddVisitMutation
} = chatApiSlice;