/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import buyedPostTableData from "./data/buyedPostTableData";
import {useEffect, useState} from "react";
import newclientTableData from "../newClient/data/newclientTableData";
import {useGetNewClientsQuery} from "../../Redux/slices/newClientApiSlice";
import {useGetPostsAvailable, useGetPostsAvailableQuery} from "../../Redux/slices/postApiSlice";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {useCreateChatMutation} from "../../Redux/slices/chatApiSlice";
import {useNavigation} from "react-router";
import {useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import TargetInfo from "../../components/Modals/TargetInfo";
import InfoUser from "../../components/Modals/InfoUser";
import {Link, useNavigate} from "react-router-dom";
import AdminBuyed from "../../components/Modals/AdminBuyed";
import {useCompleteBuyedMutation, useGetBuyedPostsQuery, useSetReadBuyMutation} from "../../Redux/slices/buyedApiSlice";
import MDBadge from "../../components/MDBadge";

function BuyedPost() {
    const [open, setOpen] = useState(false)
    const [completeBuyed] = useCompleteBuyedMutation()
    const navigate = useNavigate()
    const { columns } = buyedPostTableData();
    const [rows, setRows] = useState([])
    const {data: posts} = useGetBuyedPostsQuery()
    const user = useSelector(state => state.auth.userInfo.user)
    const [setReadBuy] = useSetReadBuyMutation()

    console.log(user)


    const onNavigate = (id) => {
        navigate(`/client/files/${id}`)
    }
    const onComplete = async (id) => {
        const res = await completeBuyed(id)
        infoToast("Successfully completed!")
    }
    useEffect(() => {
        if (posts){
            posts.data.posts.forEach(async (item) => {
                if (item.isNew){
                    const res1 = await setReadBuy(item.id)
                }
            })
        }
    }, [posts])


    useEffect(() => {
        let arr = []
        if (posts){
            arr = posts.data.posts.map(item => {
                return  {
                    id: <MDTypography>{item.id}</MDTypography>,
                    postName: (
                        <MDTypography>{item.postName}</MDTypography>
                    ),
                    user: (
                        <MDTypography>{item.user}</MDTypography>
                    ),
                    isCompleted: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={item.isCompleted ? "completed" : "Not completed"} color={item.isCompleted ? "success" : "error"} variant="gradient" size="sm" />
                        </MDBox>                    ),
                    complete: <Tooltip title={'Complete the buyed'}><MDButton onClick={() => onComplete(item.id)} variant={'contained'} color={'success'}><Icon>done</Icon></MDButton></Tooltip>,
                    price: (
                        <MDTypography>{parseFloat(item.price).toFixed(1)}</MDTypography>
                    ),

                    files: (
                        <>
                            <AdminBuyed paths={item.files}/>
                        </>

                    ),


                }
            })
        }
        setRows(arr)
    }, [posts])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid xs={9}>
                                        <MDTypography variant="h6" color="white">
                                            Buyed Posts
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                {!posts ? "Loading" : (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                )}
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default BuyedPost;
