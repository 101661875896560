/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import clientTargetTableData from "./data/clientTargetTableData";
import {useEffect, useState} from "react";
import newclientTableData from "../newClient/data/newclientTableData";
import {useGetNewClientsQuery} from "../../Redux/slices/newClientApiSlice";
import {useGetPostsAvailable, useGetPostsAvailableQuery} from "../../Redux/slices/postApiSlice";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {useCreateChatMutation} from "../../Redux/slices/chatApiSlice";
import {useNavigation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import TargetInfo from "../../components/Modals/TargetInfo";
import InfoUser from "../../components/Modals/InfoUser";
import {Link, useNavigate} from "react-router-dom";
import {useGetBalanceQuery} from "../../Redux/slices/userApiSlice";
import {setCredentials} from "../../Redux/slices/authSlice";

function ClientTarget() {
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { columns } = clientTargetTableData();
    const [rows, setRows] = useState([])
    const {data: posts, isLoading} = useGetPostsAvailableQuery()
    const [createChat] = useCreateChatMutation()
    const user = useSelector(state => state.auth.userInfo.user)

    const {data: balance} = useGetBalanceQuery(user ? user.username: '')



    const onCreate =  async (partnerId, target) => {
        try {
            const res = await createChat({userId: user.id, target, title: user.username, partnerId}).unwrap()
            infoToast("Chat Created!")
        } catch (e) {
            errorToast("You have already created the chat with this target")
        }
    }
    useEffect(() => {
        if(user.balance <= 200){
            infoToast("Deposit minimum 200$ to buy files")

        }
    }, [])
    const onNavigate = (id) => {
        navigate(`/client/files/${id}`)
    }

    useEffect(() => {
        let arr = []
        if (posts){
            arr = posts.data.map(item => {
                return  {
                    id: <MDTypography>{item.id}</MDTypography>,
                    name: (
                        <MDTypography>{item.title}</MDTypography>
                    ),
                    oneprice: (
                        <MDTypography>{item.onediscount ? item.onediscount : item.oneprice}$</MDTypography>
                    ),
                    price: (
                        <MDTypography>{item.discount ? item.discount : item.price}$</MDTypography>
                    ),

                    action: (
                        <>
                            {item.isComputers ? (
                                <Tooltip title={'Buy files'}>
                                    <MDButton onClick={() => onNavigate(item.id)}  color={'info'} style={{marginRight: 15}} variant={'contained'}><Icon>source</Icon></MDButton>
                                </Tooltip>
                            ) : null}
                            <Tooltip title={'Buy'}>
                                <MDButton onClick={() => onCreate(item.partnerId, item.title)}  color={'success'} style={{marginRight: 15}} variant={'contained'}><Icon>shopping_cart</Icon></MDButton>
                            </Tooltip>
                            <InfoUser item={item}/>
                        </>

                    ),


                }
            })
        }
        setRows(arr)
    }, [posts])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid xs={9}>
                                        <MDTypography variant="h6" color="white">
                                            Products Available
                                        </MDTypography>
                                    </Grid>
                                    <Grid xs={3}>
                                        <MDBox>
                                            <MDTypography variant="h6" color="white">
                                                Balance: {balance ? parseFloat(balance.data.balance).toFixed(1) : 0} $
                                                <Tooltip title={"Add Balance"}>
                                                    <Link to={'/client/addBalance'}>
                                                        <MDButton style={{marginLeft: 20}} >
                                                            <Icon>add</Icon>
                                                        </MDButton>
                                                    </Link>
                                                </Tooltip>
                                            </MDTypography>

                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                {isLoading ? "Loading" : (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                )}
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ClientTarget;
