import { createSlice, configureStore } from '@reduxjs/toolkit';

//MOVEMENTS (doesn't work yet)
const initialState = {
    cart: {
        files: [],
        totalPrice: 0
    },
};

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        setCart(state, action) {
            state.cart = action.payload
        },

    },
});
export const {setCart} = cartSlice.actions;
export default cartSlice.reducer;
