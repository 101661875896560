/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";

// react-router-dom components
import {Link, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/kitties.jpg";
import {useDispatch} from "react-redux";
import {useLoginPartnerMutation} from "../../../Redux/slices/userApiSlice";
import {setCredentials} from "../../../Redux/slices/authSlice";
import {errorToast} from "../../../utils/toastify";
import Captcha from "demos-react-captcha";



function PartnerSign() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loginPartner, {data, error, isLoading }] = useLoginPartnerMutation();
    const [rememberMe, setRememberMe] = useState(false);
    const [captcha, setCaptcha] = useState(false)


    const onCaptcha = (value) => {
        setCaptcha(value)
    }
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            if (captcha){
                const res = await loginPartner({ username, password }).unwrap();
                dispatch(setCredentials({ ...res }));
                navigate('/partner/partnerTargets')
            } else {
                errorToast("You entered the wrong captcha");

            }
        } catch (err) {
            errorToast(err?.data?.error || err.error);
        }
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Sign in
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox onSubmit={submitHandler} component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="text" value={username} onChange={e => setUsername(e.target.value)} label="Username" fullWidth />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" value={password} onChange={e => setPassword(e.target.value)} label="Password" fullWidth />
                        </MDBox>
                        <Captcha
                            onChange={onCaptcha}
                            placeholder="Enter captcha" // optional
                            length={8} // default
                            char={(1, 2, 3, 4, 5, 6, 7, 8, 9)}
                        />
                        <MDBox mt={4} mb={1}>
                            <MDButton type={'submit'} variant="gradient" color="info" fullWidth>
                                {isLoading ? "Loading" : "sign in"}
                            </MDButton>
                        </MDBox>

                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default PartnerSign;
