

import React from "react";
import { createRoot } from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";


// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import AdminApp from "AdminApp";
import Client from "./layouts/client";
import ClientApp from "./ClientApp";
import PartnerApp from "./PartnerApp";
import Main from "./layouts/main";
import ProductPage from "./layouts/productPage";
import {Provider} from "react-redux";
import store from "./store";
import {ToastContainer} from "react-toastify";
import FileTree from "./layouts/fileTree";
import ClientRegister from "./layouts/authentication/sign-in/ClientRegister";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ToastContainer position="top-right" newestOnTop />

            <MaterialUIControllerProvider>
                <Routes>
                    <Route exact path={'/affadmeowsugarhgvhgvhgv/*'} element={<AdminApp/>}></Route>

                    <Route exact path={'/client/*'} element={<ClientApp/>}></Route>

                    <Route exact path={'/partner/*'} element={<PartnerApp/>}></Route>
                    <Route exact path={'/'} element={<Main/>}></Route>
                    <Route exact path={'/product/:id'} element={<ProductPage/>}></Route>




                </Routes>
            </MaterialUIControllerProvider>
        </BrowserRouter>
    </Provider>
);
