/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import cartTableData from "./data/cartTableData";
import React, {useEffect, useState} from "react";
import CreateClient from "../../components/Modals/CreateClient";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePassword from "../../components/Modals/UpdatePassword";
import {
    useDeleteUserMutation,
    useGetBalanceQuery,
    useGetClientsQuery,
    useGetOnlineQuery
} from "../../Redux/slices/userApiSlice";
import {errorToast, infoToast} from "../../utils/toastify";
import MDBadge from "../../components/MDBadge";
import {formatter} from "../../utils/fornatter";
import {useDispatch, useSelector} from "react-redux";
import {setCart} from "../../Redux/slices/cartSlice";
import {useCreateChatMutation} from "../../Redux/slices/chatApiSlice";
import {useParams} from "react-router";
import {useGetPostByIdQuery} from "../../Redux/slices/postApiSlice";
import {useSendBuyMutation} from "../../Redux/slices/buyedApiSlice";
import {useNavigate} from "react-router-dom";

function getBaseName(filename) {
    return filename.substring(0, filename.lastIndexOf('.'));
}
function getFileExtension(filename) {
    const index = filename.lastIndexOf('.');
    return index !== -1 ? filename.substring(index + 1) : '';
}

function Cart() {
    const [open, setOpen] = useState(false)
    const { columns } = cartTableData();
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const [rows, setRows] = useState([])

    const handleChange = (node) => {


            const finalFiles = cart.files.filter(item => item.path !== node.path);
            dispatch(setCart({totalPrice: cart.totalPrice - node.price, files: finalFiles}))



    };
    const [createChat] = useCreateChatMutation()


    const id = localStorage.getItem('postId')
    const {data: post, isLoading} = useGetPostByIdQuery({id})
    const user = useSelector(state => state.auth.userInfo.user)

    const [sendBuy] = useSendBuyMutation()
    const navigate = useNavigate()

    const {data: balance} = useGetBalanceQuery(user ? user.username: '')
    const removeAllFromCart = () => {
        dispatch(setCart({files:  [], totalPrice: 0}))

    }



    const submitHandler = async (e) => {
        try{
            e.preventDefault()
            await createChat({userId: user.id, target: post.data.title + ' Files', title: user.username + ' files', partnerId: post.data.partnerId }).unwrap()

            await sendBuy({files: cart.files, username: user.username, price: cart.totalPrice, postName: post.data.title, partnerId: post.data.partnerId})
            dispatch(setCart({files:  [], totalPrice: 0}))
            infoToast("We will contact you in chat section")
            navigate('/client/clientBuy')
            const currentBalance = localStorage.getItem('balance')
        } catch (e){
            errorToast("You have already created the chat with this target")

        }
    }



    useEffect(() => {
        let arr = []
            arr = cart.files.map(item => {
                return  {
                    name: <MDTypography>{getBaseName(item.name)}</MDTypography>,
                    ext: <MDTypography>{getFileExtension(item.name)}</MDTypography>,
                    price: (
                        <MDTypography>{item.price} $</MDTypography>
                    ),
                    weight: (
                        <MDTypography>{item.weight} KB</MDTypography>
                    ),


                    date: (
                        <MDTypography>{item.date}</MDTypography>
                    ),
                    action: (
                        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                            <Tooltip title={'Delete'}>
                                <MDButton color={'error'} onClick={() => handleChange(item)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                            </Tooltip>
                        </MDTypography>
                    ),


                }
            })
        setRows(arr)
    }, [cart])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDTypography variant="h6" color="white">
                                            Your cart
                                        </MDTypography>
                                        <Tooltip title={"Remove all from cart"}>
                                            <MDButton onClick={removeAllFromCart}>
                                                <Icon>delete</Icon>
                                            </MDButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <MDTypography variant="h6"  color={'white'}>
                                            Total Price: {parseFloat(cart.totalPrice).toFixed(1)}$
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                {cart ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                ) : "loading"}
                            </MDBox>
                            <form style={{width: '100%'}} onSubmit={submitHandler}>

                                <MDButton fullWidth type={'submit'} disabled={balance ? balance.data.balance < cart.totalPrice || cart.totalPrice === 0 ? true : false : true} variant={'contained'} color={'info'}>{balance ? cart.totalPrice === 0 ? "Select some files" : balance.data.balance < cart.totalPrice ? "You don't have money on your balance" : `Buy for ${parseFloat(cart.totalPrice).toFixed(1)}$`: "You don't have money on your balance" }</MDButton>
                            </form>

                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Cart;
