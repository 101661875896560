import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import MDTypography from "../../../components/MDTypography";
import ListItem from "@mui/material/ListItem";
import MDBox from "../../../components/MDBox";
import {useSelector} from "react-redux";
import {useDeleteMessageMutation, useUpdateReadMutation} from "../../../Redux/slices/messageApiSlice";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

const Message = ({message}) => {
    const user = useSelector(state => state.auth.userInfo.user)
    const [deleteMessage] = useDeleteMessageMutation()
    const fromSelf = user.id === message.sender
    const [sent, setSent] = useState('')
    const chat = useSelector(state => state.chats.chatInfo)

    const [updateRead] = useUpdateReadMutation()
    useEffect(() => {

        const dt = new Date(message.createdAt)
        setSent(dt)
    }, [])
    useEffect(() => {
        if (user.id !== message.sender && user.isPartner === false){
            const res = updateRead({messageId: message.id, receiver: message.receiver})
        }
    }, [])
    const onDelete =  async () => {
        const res = await deleteMessage({messageId: message.id, receiver: chat.id})
    }
    return (
        <ListItem key="2">
            <Grid container>
                <Grid item align={"right"} xs={12}>
                    <MDBox

                        py={1}
                        px={1}
                        ml={fromSelf ? '50%' : null}
                        mr={fromSelf ? null : '50%'}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography style={{wordWrap: 'break-word'}} align={fromSelf ? 'right' : 'left'} color={'white'} primary="Hey, Iam Good! What about you ?">{message.message}
                            {user.isAdmin ? (
                                <Tooltip title={'Delete message'}>
                                    <MDButton onClick={onDelete} style={{marginLeft: '20px'}} color={'error'}><Icon >delete</Icon></MDButton>

                                </Tooltip>
                            ) : null}
                        </MDTypography>
                    </MDBox>
                </Grid>
                <Grid item xs={12}>
                    <MDTypography style={{fontSize: '13px'}} align={fromSelf ? 'right' : 'left'} secondary="09:31">{message.isRead && user.id === message.sender  ? "Read" : null}</MDTypography>

                    {sent ? <MDTypography style={{fontSize: '13px'}} align={fromSelf ? 'right' : 'left'} secondary="09:31">{sent.toLocaleString()}</MDTypography> : null}

                </Grid>
            </Grid>
        </ListItem>
    );
};

export default Message;