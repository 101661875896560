import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation, useCreatePartnerMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {InputAdornment} from "@mui/material";
import {
    useApproveTargetMutation,
    useDeclineTargetMutation,
    useEditTargetMutation
} from "../../Redux/slices/targetApiSlice";


export default function ApproveTarget({id}) {




    const [approveTarget, {isLoading}] = useApproveTargetMutation()


    const submitHandler = async (e) => {

        e.preventDefault();
        if (confirm("Are you sure?")){
            try {

                const res = await approveTarget({id}).unwrap();

                infoToast(`Success`)

            } catch (err) {
                errorToast(err.error);
            }
        }
    };



    return (
        <React.Fragment>
            <Tooltip title={'Approve'}>
                <MDButton style={{marginRight: 15}} onClick={submitHandler} color={'success'} ><Icon>check_circle_outline</Icon></MDButton>
            </Tooltip>

        </React.Fragment>
    );
}