import { apiSlice } from './apiSlice';
import {current} from "@reduxjs/toolkit";
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});


export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginClient: builder.mutation({
            query: (data) => ({
                url: `auth/loginClient`,
                method: 'POST',
                body: data,
            }),
        }),
        getClient: builder.query({
            query: () => ({
                url: `/user/getClient/:id`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['User'],
        }),
        loginPartner: builder.mutation({
            query: (data) => ({
                url: `/auth/loginPartner`,
                method: 'POST',
                body: data,
            }),
        }),
        loginAdmin: builder.mutation({
            query: (data) => ({
                url: `/auth/loginAdm1n`,
                method: 'POST',
                body: data,
            }),
        }),
        getClients: builder.query({
            query: () => ({
                url: `/user/getClients`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['User'],
        }),
        getBalance: builder.query({
            query: (username) => ({
                url: `/user/getBalance/${username}`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("COMPLETED_PAYMENT", (data) => {
                        updateCachedData((draft) => {
                            const user = localStorage.getItem('userInfo')
                            let finalUser = JSON.parse(user)
                            console.log('draft', data)
                            console.log(finalUser.user)

                            if (data.id === finalUser.user.id){
                                infoToast(`The payment is completed!`)
                                draft.data.balance = parseFloat(data.balance)

                            }
                        });
                    });
                    socket.on("NEW_BUYS", (data) => {
                        updateCachedData((draft) => {
                            const user = localStorage.getItem('userInfo')
                            let finalUser = JSON.parse(user)
                            console.log('draft', data)
                            console.log(finalUser.user)

                            if (data.id === finalUser.user.id){
                                draft.data.balance = parseFloat(data.balance)

                            }
                        });
                    });
                    // socket.on("NEW_BUYS", (data) => {
                    //     updateCachedData((draft) => {
                    //         const user = localStorage.getItem('userInfo')
                    //         let finalUser = JSON.parse(user)
                    //
                    //         if (draft.data.user.id === finalUser.user.id){
                    //             draft.data = JSON.parse(JSON.stringify(data))                            }
                    //     });
                    // });



                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['User'],
        }),

        getPartners: builder.query({
            query: () => ({
                url: `/user/getPartners`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['User'],
        }),
        getOnline: builder.query({
            query: () => ({
                url: `/usersOnline`,
            }),

            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    socket.on("ONLINE_USER_CHANGED", (online) => {
                        updateCachedData((draft) => {
                            console.log(online)

                            draft.data = JSON.parse(JSON.stringify(online))

                        });
                    });

                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            providesTags: ['Online'],
        }),

        updatePasswordPartner: builder.mutation({
            query: (data) => ({
                url: `/user/updatePartner/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updatePasswordClient: builder.mutation({
            query: (data) => ({
                url: `/user/updateClient/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        createClient: builder.mutation({
            query: (data) => {
                socket.emit("REGISTER_CLIENT", data)
            },
            invalidatesTags: ['User'],
        }),
        createClientAdmin: builder.mutation({
            query: (data) => ({
                url: `/user/createClient`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        createPartner: builder.mutation({
            query: (data) => ({
                url: `/user/createPartner`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/user/delete/${id}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['User'],
        }),
        blockPartner: builder.mutation({
            query: (id) => ({
                url: `/user/setBlocked/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['User'],
        }),
        saleInfo: builder.query({
            query: () => ({
              url: "/chat/getInfo"
            })
        })

    }),
});

export const {
    useLoginClientMutation,
    useLoginPartnerMutation,
    useLoginAdminMutation,
    useGetClientsQuery,
    useGetPartnersQuery,
    useDeleteUserMutation,
    useCreateClientMutation,
    useCreatePartnerMutation,
    useUpdatePasswordClientMutation,
    useUpdatePasswordPartnerMutation,
    useGetOnlineQuery,
    useBlockPartnerMutation,
    useSaleInfoQuery,
    useGetBalanceQuery,
    useCreateClientAdminMutation

} = userApiSlice;