/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {Treebeard} from 'react-treebeard';


// Data
import React, {useEffect, useState} from "react";
import newclientTableData from "../newClient/data/newclientTableData";
import {useGetNewClientsQuery} from "../../Redux/slices/newClientApiSlice";
import {useGetPostByIdQuery, useGetPostsAvailable, useGetPostsAvailableQuery} from "../../Redux/slices/postApiSlice";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {useCreateChatMutation} from "../../Redux/slices/chatApiSlice";
import {useNavigation, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import TargetInfo from "../../components/Modals/TargetInfo";
import InfoUser from "../../components/Modals/InfoUser";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TreeView, {TreeExample} from "./TreeView";
import {useSendBuyMutation} from "../../Redux/slices/buyedApiSlice";
import {setCart} from "../../Redux/slices/cartSlice";
import {FormControl, InputLabel, NativeSelect, Pagination} from "@mui/material";
import {useGetBalanceQuery} from "../../Redux/slices/userApiSlice";
import {setFinalPath} from "../../Redux/slices/pathSlice";

function FileTree() {
    const [createChat] = useCreateChatMutation()
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const path = useSelector(state => state.path.path)
    const {id} = useParams()
    const [rows, setRows] = useState(20)

    const {data: post, isLoading} = useGetPostByIdQuery({id, path, page: currentPage, rows})

    const user = useSelector(state => state.auth.userInfo.user)
    const cart = useSelector(state => state.cart.cart)
    const [sendBuy] = useSendBuyMutation()
    const navigate = useNavigate()

    const {data: balance} = useGetBalanceQuery(user ? user.username: '')
    const onRows = (event) => {
        setRows(event.target.value)

    }
    const location = useLocation();


    useEffect(() => {
        dispatch(setFinalPath([]))
    }, [location]); // Зависимость от location

    useEffect(() => {
        const preId = localStorage.getItem("postId")
        if (post){

            localStorage.setItem('postId', post.data.id)
            localStorage.setItem('image', post.data.images[0])
        }
    }, [post])

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    const submitHandler = async (e) => {
        e.preventDefault()


        navigate('/client/cart')
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <form onSubmit={submitHandler}>
                            <Card>

                                <MDBox pt={3}>
                                    {post ? <TreeView onSet={setCurrentPage} loading={isLoading} post={post.data} rows={rows} dataa={post.files}/>: null}

                                    <MDBox mb={5}>
                                        {post     ?
                                            <>
                                                <Grid container>
                                                    <Grid item xs={5}>
                                                        <Pagination  style={{marginTop: '80px'}} color={'info'} count={post.files.totalPages} page={currentPage}  onChange={handleChange} />

                                                    </Grid>
                                                    <Grid item xs={3}>
                                                            <FormControl style={{marginTop: 80, width: 200, marginLeft: 300}}>
                                                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                                    Rows per page
                                                                </InputLabel>
                                                                <NativeSelect
                                                                    defaultValue={rows}
                                                                    onChange={onRows}
                                                                    inputProps={{
                                                                        name: 'age',
                                                                        id: 'uncontrolled-native',
                                                                    }}
                                                                >
                                                                    <option value={20}>20</option>
                                                                    <option value={40}>40</option>
                                                                    <option value={60}>60</option>
                                                                    <option value={80}>80</option>
                                                                    <option value={100}>100</option>

                                                                </NativeSelect>
                                                            </FormControl>
                                                    </Grid>

                                                </Grid>
                                            </>
                                            : null}
                                    </MDBox>
                                </MDBox>

                                <MDButton type={'submit'} disabled={balance ? balance.data.balance < cart.totalPrice || cart.totalPrice === 0 ? true : false : true} variant={'contained'} color={'info'}>{balance ? cart.totalPrice === 0 ? "Select some files" : balance.data.balance < cart.totalPrice ? "You don't have money on your balance" : `Buy for ${parseFloat(cart.totalPrice).toFixed(1)}$`: "You don't have money on your balance" }</MDButton>

                            </Card>

                        </form>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FileTree;
