
import { createSlice, configureStore } from '@reduxjs/toolkit';

//MOVEMENTS (doesn't work yet)
const initialState = {
    path: [],

};

const searchSlice = createSlice({
    name: 'search',
    initialState: initialState,
    reducers: {
        setFinalSearch(state, action) {
            state.search = action.payload
        },

    },
});
export const {setFinalSearch} = searchSlice.actions;
export default searchSlice.reducer;
