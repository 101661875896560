import * as React from 'react';
import AccordionGroup from '@mui/joy/AccordionGroup';

import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

export default function FaqSet() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3} mx={10}>
                <Accordion>
                    <AccordionSummary id="panel-header" aria-controls="panel-content">
                        How to sell?
                    </AccordionSummary>
                    <AccordionDetails>
                        You can add a target by going to "Sell Target" section
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary id="panel-header" aria-controls="panel-content">
                        What should I do after adding a target?
                    </AccordionSummary>
                    <AccordionDetails>
                        After adding a target, it is sent to the "Targets" section for verification.
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary id="panel-header" aria-controls="panel-content">
                        Where are my targets?
                    </AccordionSummary>
                    <AccordionDetails>
                        You will see the added targets in the "Posts" section.
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary id="panel-header" aria-controls="panel-content">
                        What's next?
                    </AccordionSummary>
                    <AccordionDetails>
                        When a buyer is interested in the target, you will see a window in the "Chats" section (where you negotiate with them).<br/>
                        Prohibited (For which you may receive a BAN, payments will be suspended):<br/>
                        1. SCO countries<br/>
                        2. Medicine, Disabled Children and anything that can interfere with human life - This is too low.<br/>
                        3. It is forbidden to analyze the site, without the permission of the administration     <br/>               </AccordionDetails>
                </Accordion>

            </MDBox>
        </DashboardLayout>
    );
}