import { apiSlice } from './apiSlice';
const headers = {
        'Content-Type': 'multipart/form-data',
}
export const postApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPosts: builder.query({
            query: (data) => ({
                url: `/post/getPosts?page=${data.currentPage}&search=${data.searchKey || ""}`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Post'],
        }),
        getPostsAdmin: builder.query({
            query: (data) => ({
                url: `/post/getPostsAdmin`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Post'],
        }),
        getPostsAvailable: builder.query({
            query: () => ({
                url: `/post/getPostsAvailble`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Post'],
        }),
        getPostById: builder.query({

            query: (data) => ({
                url: `/post/getPost/${data.id}`,
                params: {
                path: data.path ? data.path.map(encodeURIComponent).join(',') : '', // Encode each path element
                page: data.page ? data.page : 1,
                rows: data.rows ? data.rows : 20
            }
        }),
        keepUnusedDataFor: 5,
        providesTags: ['Post']
    }),

        getPostsByPartner: builder.query({
            query: (id) => ({
                url: `/post/getPostsByPartner/${id}`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Post']
        }),

        deletePost: builder.mutation({
            query: (data) => ({
                url: `/post/deletePost`,
                method: 'DELETE',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        createPost: builder.mutation({
            query: (data) => ({
                url: `/post/createPost`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Post'],
        }),
        editPost: builder.mutation({

            query: (data) => ({
                url: `/post/editPost`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Post'],
        }),
        deleteComputer: builder.mutation({

            query: (data) => ({
                url: `/upload/list/${data.id}/${data.computer}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Post'],
        }),
        uploadImages: builder.mutation({

            query: (data) => ({
                url: `/upload/`,
                method: 'POST',
                body: data,
                headers
            }),
        }),

    }),
});

export const {
    useGetPostsQuery,
    useCreatePostMutation,
    useDeletePostMutation,
    useEditPostMutation,
    useGetPostByIdQuery,
    useUploadImagesMutation,
    useGetPostsAvailableQuery,
    useGetPostsByPartnerQuery,
    useGetPostsAdminQuery,
    useDeleteComputerMutation

} = postApiSlice;