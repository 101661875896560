import React, {useState} from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import { teal, grey } from "@material-ui/core/colors";
import {Modal} from "@mui/material";
import MDBox from "../../../components/MDBox";
import Box from "@mui/material/Box";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const countries = [
    {
        value: "USA",
        label: "USA"
    },
    {
        value: "EUR",
        label: "EUR"
    },
    {
        value: "BTC",
        label: "BTC"
    },
    {
        value: "JPY",
        label: "JPY"
    }
];

function PartnerEdit(props) {
    const { classes, open, onClose } = props;
    const [values, setValues] = React.useState({
        shipping: "Cat in the Hat",
        country: "",
        city: "",
        state: "",
        postalCode: "",
        address: ""
    });
    const [open1, setOpen1] = useState(false)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open || open1}
            onClose={() => onClose("wireModal")}

            closeAfterTransition

            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Box sx={style}>
                <Grid container>
                    <Grid item xs={8}>
                        <Grid container direction="row" >
                            <Grid item xs={8}>
                                <Typography variant="h5">
                                    Ship To My Address
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    variant="subtitle1"
                                    align="right"
                                >
                                    Buyer Shipping Info
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item xs={10}>
                                <TextField
                                    style={{ marginBottom: 20 }}
                                    fullWidth
                                    select
                                    margin="dense"
                                    variant="outlined"
                                    label="Shipping Presets"
                                    defaultValue="None"
                                    id="shipping-presets"
                                >
                                    <MenuItem>None Present</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    style={{ marginTop: 20 }}
                                    label="Country"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    value={values.country}
                                    id="country"
                                    margin="dense"
                                    helperText="Please select your country"
                                >
                                    {countries.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label="City"
                                    id="city"
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label="State/Province"
                                    id="state-province"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    //fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label="Postal Code"
                                    id="postal-code"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label="Street Address"
                                    id="address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    multiline
                                    rows="5"
                                    variant="outlined"
                                    label="Additional Info"
                                    id="additional-info"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item xs={12} align="right" >
                                <IconButton
                                    edge="start"
                                    align="right"
                                    color="inherit"
                                    aria-label="Close"
                                    style={{ padding: 8 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Typography variant="h5">
                                    Seller Shipping From
                                </Typography>
                            </Grid>
                            <Grid container style={{ paddingTop: 20 }}>
                                <Grid item xs={2}>
                                    <Icon >location_on</Icon>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography >
                                        36 BAOSHAN JIUCUN BAOSHAN DISTRICT{" "}
                                        <strong>201900 Shanghai China</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ paddingTop: 10, alignItems: "center" }}>
                                <Grid item xs={2}>
                                    <Icon >person</Icon>
                                </Grid>
                                <Grid item align="left" xs={3}>
                                    <Typography
                                        variant="caption"
                                    >
                                        Seller
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography align="right" >
                                        <strong>Seller Company</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-auto">
                            <Grid item container ju>
                                <Grid item xs={12}>
                                    <Button>Cancel</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button>SAVE</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default PartnerEdit;
