import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch, useSelector} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation, useCreatePartnerMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {InputAdornment} from "@mui/material";
import {useDeclineTargetMutation, useEditTargetMutation} from "../../Redux/slices/targetApiSlice";


export default function DeclineTarget({item}) {

    const [reason, setReason] = useState("")
    const [has, setHas] = useState(false)
    const user = useSelector(state => state.auth.userInfo.user)


    const onReason = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setReason(e.target.value)
        }
    }



    const [declineTarget, {isLoading}] = useDeclineTargetMutation()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const submitHandler = async (e) => {

        e.preventDefault();
        try {

            const res =  declineTarget({reason, id: item.id, partnerId: item.partnerId, userId: user.id});

            infoToast(`Success`)

        } catch (err) {
            errorToast(err.error);
        }
    };
    const [open, setOpen] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title={'Decline'}>
                <MDButton style={{marginRight: 15}} color={'error'} onClick={handleClickOpen}><Icon>highlight_off</Icon></MDButton>
            </Tooltip>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <MDBox sx={style}>
                        <form onSubmit={submitHandler}>
                            <Grid mb={5} container>

                                <Grid container direction="row">
                                    <Grid item xs={8}>
                                        <MDTypography variant="h5">
                                            Decline Target
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"

                                    spacing={3}
                                >
                                    <Grid item mt={3} xs={12}>
                                        <MDInput
                                            value={reason}
                                            onChange={onReason}
                                            fullWidth
                                            margin="dense"
                                            type={'text'}

                                            variant="outlined"
                                            label="Reason"
                                            id="Reason"
                                        />
                                    </Grid>



                                </Grid>


                            </Grid>
                            <MDButton type={'submit'} color={'error'} style={{marginTop: 4}}>{isLoading ? "Loading..." : "Decline"}</MDButton>

                        </form>
                    </MDBox>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}